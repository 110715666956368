<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>
        <v-row no-gutters>
          <v-btn @click="$router.back()" color="red white--text"
            ><v-icon class="mr-2">fa fa-arrow-left</v-icon>Back</v-btn
          >
          <v-spacer />
          <v-btn @click="saveRole" color="primary">{{
            checkEditPage() ? "Save Role" : "Submit New Role"
          }}</v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="roleForm" v-model="isValid" :rules="rules">
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="formData.name"
                label="Role Name"
                outlined
                dense
                required
                :rules="rules.name"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="formData.display_name"
                label="Display Name"
                outlined
                dense
                required
                :rules="rules.display_name"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="formData.status"
                label="Status"
                outlined
                dense
                :items="statuses"
                :item-text="(item) => item.name"
                :item-value="(item) => item.name"
                required
                :rules="rules.status"
              />
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="formData.description"
                label="Description"
                outlined
                dense
                required
                :rules="rules.description"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="2"
            v-bind:key="perm.id"
            v-for="perm in permissions"
          >
            <v-checkbox
              class="text-capitalize"
              v-model="selectedPermissions"
              @change="pushPermissions()"
              :label="perm.display_name"
              hide-detail
              :value="perm.name"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "AccessRoleCard",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("AccessControl/permissions");
      if (to.params.code) {
        v.$store.dispatch("AccessControl/role", to.params.code);
      }
    });
  },
  data: () => ({
    selectedPermissions: [],
    isValid: false,
    saving: false,
    isEdit: false,
    formData: {
      name: "",
      display_name: "",
      description: "",
      permissions: [],
    },
    rules: {
      name: [(v) => !!v || "Name is required"],
      status: [(v) => !!v || "Status is required"],
      display_name: [(v) => !!v || "Display Name is required"],
      description: [(v) => !!v || "Description is required"],
    },
    statuses: [
      {
        name: "ACTIVE",
      },
      {
        name: "INACTIVE",
      },
    ],
  }),
  mounted() {
    Event.$on("savingRole", (value) => {
      this.saving = value;
    });
  },
  computed: {
    permissions() {
      return this.$store.getters["AccessControl/accessGetters"]("permissions");
    },
    role() {
      return this.$store.getters["AccessControl/accessGetters"]("role");
    },
  },
  methods: {
    //check if page is to be edited
    checkEditPage() {
      return !!this.$route.params.code;
    },
    //push
    pushPermissions() {
      this.formData.permissions = this.selectedPermissions;
    },
    //save role
    saveRole() {
      this.saving = true;
      if (!this.isValid) {
        this.saving = false;
        this.$refs.roleForm.validate();
      } else {
        if (this.formData.permissions.length > 0) {
          console.log(this.checkEditPage());
          if (!this.checkEditPage()) {
            this.$store.dispatch("AccessControl/saveRole", {
              ...this.formData,
            });
          } else {
            this.formData.permissions = this.selectedPermissions;
            this.$store.dispatch("AccessControl/editRole", {
              ...this.formData,
            });
          }
        } else {
          Event.$emit("ApiError", "A role must have associated permissions");
        }
      }
    },
  },
  watch: {
    role(data) {
      this.formData = { ...data };
      this.formData.permissions = data.permissions;
      for (let perm of data.permissions) {
        this.selectedPermissions.push(perm.name);
      }
    },
  },
};
</script>

<style scoped></style>
