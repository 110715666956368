<template>
  <v-card flat class="d-flex flex-row">
    <v-card
      color="drawerBackground"
      class="mr-2"
      flat
      v-for="(status, i) in validStatus"
      width="300"
      :loading="kanbanLoading"
      :key="i"
    >
      <v-card-title>
        <span class="board-header"></span>{{ status }}
        {{ issueCount(status) }}
      </v-card-title>
      <draggable
        class="list-group"
        handle=".handle"
        :group="{ name: status, put: true }"
        :list="orderedIssues[status]"
        :move="onMove"
        @start="isDragging = true"
        @end="dragged"
      >
        <transition-group type="transition" name="flip-list">
          <v-card
            v-for="(element, i) in orderedIssues[status]"
            :key="i"
            :ref="element.issueCode"
            :elevation="!element.issueCode ? 0 : 1"
            :color="!element.issueCode ? 'drawerBackground' : ''"
            :class="`ma-2 list-group-item ${element.issueCode ? 'handle' : ''}`"
            @click="selectIssue(element)"
          >
            <v-card-title v-if="element.issueCode" class="text-body-2">
              <span v-html="element.description"></span>
            </v-card-title>
            <v-card-title v-else class="text-body-2 d-flex flex-column">
              <span class="align-self-center">{{ element.description }}</span>
            </v-card-title>
            <v-toolbar
              flat
              dense
              :color="!element.issueCode ? 'drawerBackground' : ''"
            >
              <v-chip
                v-if="element.issueType"
                label
                small
                :color="`${issueTypeColor(
                  element.issueType.toLowerCase()
                )} lighten-5`"
                :class="`${issueTypeColor(
                  element.issueType.toLowerCase()
                )}--text text--darken-4 text-capitalize mr-2`"
                >{{ element.issueType }}</v-chip
              >
              <span class="text-body-2 text-wrap" v-if="element.issueCode">{{
                element.issueCode
              }}</span>
              <v-spacer />
              <v-tooltip bottom v-if="element.issueCode">
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    v-on="on"
                    v-bind="attrs"
                    class="mr-2 white--text"
                    :size="24"
                    :color="
                      element.assignee !== null
                        ? userColor(element.assignee)
                        : 'grey'
                    "
                  >
                    <span v-if="element.assignee === null"
                      ><v-icon>fa-solid fa-user</v-icon></span
                    >
                    <span v-else>{{ convertArrtoObj(element.assignee) }}</span>
                  </v-avatar>
                </template>
                <span>{{
                  element.assignee ? element.assignee.username : "Unassigned"
                }}</span>
              </v-tooltip>
            </v-toolbar>
          </v-card>
        </transition-group>
      </draggable>
    </v-card>
  </v-card>
</template>
<script>
import draggable from "vuedraggable";
import issueMixin from "../../issueMixin";
export default {
  name: "Kanban",
  mixins: [issueMixin],
  props: {
    issues: {
      default: () => [],
      type: Array,
    },
  },
  components: {
    draggable,
  },
  data: function () {
    return {
      tickets: [],
      isDragging: false,
      delayedDragging: false,
      selectedIssueText: null,
      selectedIssue: {},
    };
  },
  computed: {
    validStatus: function () {
      return this.$store.getters["Ticket/issueGetters"]("validStatuses");
    },
    kanbanLoading: function () {
      return this.$store.getters["Ticket/issueGetters"]("kanbanLoading");
    },
    orderedIssues: function () {
      let issues = {};
      this.validStatus.forEach((status) => {
        issues[status] = this.issues.filter((issue) => {
          return issue.status === status;
        });
        issues[status].length === 0
          ? (issues[status] = [{ description: "No issues" }])
          : "";
      });
      return issues;
    },
  },
  methods: {
    dragged: function (evt) {
      console.log("dragged", evt.to.textContent);
      if (evt.to.textContent === this.selectedIssueText) {
        this.$store.dispatch("Ticket/updateStatusKanban", this.selectedIssue);
      }
    },
    onMove(evt) {
      console.log("onMove", evt);
      this.selectedIssueText = evt.to.textContent;
      const targetStatus = evt.relatedContext.component.$attrs.group;
      const issue = evt.draggedContext.element;
      this.selectedIssue = {
        issueCode: issue.issueCode,
        status: targetStatus.name,
      };
    },
    dragOptions(status) {
      return {
        animation: 200,
        group: status,
        disabled: false,
        handle: ".board-header",
        ghostClass: "ghost",
      };
    },
    issueCount: function (status) {
      const issueCount = this.orderedIssues[status].filter((issue) => {
        return issue.issueCode;
      }).length;
      return issueCount > 0
        ? `${issueCount} issue${issueCount === 1 ? "" : "s"}`
        : "";
    },
  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
  },
};
</script>
<style lang="sass">
@import '~@/packages/tickets/modules/issues/styles/kanban.sass'
</style>
