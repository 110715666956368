import { helper } from "@/packages/dashboard/utils";
import colors from "./styles/colors";
export default {
  data: function () {
    return {
      search: "",
    };
  },
  computed: {
    customToolbar() {
      return [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ];
    },
  },
  methods: {
    issueTypeColor(type) {
      return colors.issueType()[type];
    },
    userColor(user) {
      return helper.stringToColour(user);
    },
    issuePriorityColor(priority) {
      return helper.getPriorityColor(priority);
    },
    issueStatusColor(status) {
      return helper.getColor(status);
    },
    dateFormat(date) {
      return helper.getDateFormat(date);
    },
    TimeFormat(time) {
      const d = time.split(" ")[1];
      return helper.getTimeFormat(d);
    },
    getColor: function (status) {
      return helper.getColor(status);
    },

    selfUpdate: function (item) {
      this.selectedIssue.description = this.htmlToString(
        document.getElementById("quizOutput")
      );
      console.log(this.selectedIssue.description);
      const data = { ...this.selectedIssue };
      delete data.reporter;
      delete data.assignee;
      this.editDueDate = false;
      this.editAgent = false;
      this.editPriority = false;

      data.item = item;

      this.$store.dispatch("Ticket/selfUpdate", data);
      this.toggle = false;
    },

    assignIssue: function () {
      const data = {
        assignee: this.formData.assignee,
        issueCode: this.selectedIssue.issueCode,
        projectCode: this.selectedIssue.projectCode,
      };

      this.assignLoading = true;
      this.$store.dispatch("Ticket/assignIssue", data);
      setTimeout(() => (this.assignLoading = false), 2000);
    },
    saveComment: function () {
      const data = {
        comment: this.formData.comment,
        ticket: this.selectedIssue.id,
        commmentedBy: this.user.id,
      };

      this.$store.dispatch("Ticket/saveComment", data);
    },
    selectIssue: function (event, item) {
      if (event.type === "dblclick") {
        const issue = item.item;
        console.log("dblclick", event, issue);
        this.$router.replace({
          name: "ViewSelectedIssue",
          params: { id: issue.issueCode },
        });
      } else {
        console.log("click", event, item);
        this.$router.replace({
          name: "ViewSelectedIssue",
          params: { id: item.issueCode },
        });
      }
    },
    closeDialog: function () {
      this.$router.replace({
        name: "issueList",
      });
    },

    updateStatus: function () {
      this.$router.replace({ name: "changeStatus" });
    },
    updateTicketStatus(item) {
      const data = {
        status: item,
        issueCode: this.selectedIssue.issueCode,
        projectCode: this.selectedIssue.projectCode,
      };
      this.$store.dispatch("Ticket/UpdateTicketStatus", data);
    },
  },
};
