import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Update Issue Status "),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("fa-solid fa-xmark")])],1)],1),_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c('div',{staticStyle:{"max-width":"40%"}},[_c(VTextField,{staticClass:"search mr-2",attrs:{"filled":"","dense":"","width":"300","single-line":"","append-inner":"fa-solid fa-magnifying-glass","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.avatarsStackedLimited && _vm.avatarsStackedLimited.length > 0)?_c('section',{staticClass:"avatars-group stacked"},[_c(VBtnToggle,{attrs:{"borderless":""},model:{value:(_vm.selectAssignee),callback:function ($$v) {_vm.selectAssignee=$$v},expression:"selectAssignee"}},_vm._l((_vm.avatarsStackedLimited),function(avatar){return _c(VBtn,{key:("avatar-id-" + (avatar.id)),staticClass:"avatars-group__item",attrs:{"icon":"","value":avatar.id}},[_c('profile-avatar',{attrs:{"avatar":avatar}})],1)}),1)],1):_vm._e()]),_c(VCardText,[_c('kanban',{attrs:{"issues":(_vm.selectAssignee ? _vm.assigneeSearch : _vm.issues) ||
          (_vm.search.length > 0 ? _vm.searchIssues : _vm.issues)}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }