export default [
  {
    order: 3,
    icon: "fa-solid fa-add",
    disabled: false,
    iconColor: "primary",
    text: "Add New",
    subLinks: [
      {
        action: "addIssue",
        order: 1,
        icon: "view_agenda",
        disabled: false,
        text: "Issue",
      },
    ],
  },
];
