<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-card-title>
        Client Email Inbox/Outbox
        <v-spacer />
        <v-btn icon class="mx-2" @click="refreshMessages">
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-btn class="primary text-capitalize mx-2" @click="compose">
          <v-icon class="mx-2">email</v-icon>
          Compose Mail
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-n5">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table
          :headers="headers"
          :items="messages"
          :items-per-page="10"
          :search="search"
          class="elevation-0"
        >
          <!--eslint-disable-next-line -->
          <template v-slot:item.from="{ item }">
            <v-chip class="indigo lighten-4 indigo--text text--darken-4" label>
              {{ item.from }}
            </v-chip>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.to="{ item }">
            <v-chip
              class="primary lighten-4 primary--text text--darken-4"
              label
            >
              {{ item.to }}
            </v-chip>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.subject="{ item }">
            <a
              :class="
                item.is_read
                  ? 'text-truncate'
                  : 'primary--text font-weight-bold text-body-1 text-truncate'
              "
              style="max-width: 200px"
              @click="view(item)"
            >
              <div v-html="item.subject"></div>
            </a>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.body="{ item }">
            <div class="text-truncate text-wrap" style="max-width: 200px">
              <div v-html="item.body"></div>
            </div>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.created_at="{ item }">
            <span>{{ formatDate(item.created_at) }}</span>
          </template>
          <!--eslint-disable-next-line -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon class="mr-2" @click="view(item)" v-on="on">
                  <v-icon color="primary">visibility</v-icon>
                </v-btn>
              </template>
              <span>View Email</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mr-2"
                  @click="reply(item)"
                  v-on="on"
                  :disabled="!item.isReply"
                >
                  <v-icon color="secondary">reply</v-icon>
                </v-btn>
              </template>
              <span>Reply</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon class="mr-2" @click="forward(item)" v-on="on">
                  <v-icon color="secondary">redo</v-icon>
                </v-btn>
              </template>
              <span>Forward</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon class="mr-2" @click="deleteMessage(item)" v-on="on">
                  <v-icon color="error">delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { helper } from "@/packages/dashboard/utils";
import mailmixin from "../mailmixin";
export default {
  name: "MailList",
  mixins: [mailmixin],
  data() {
    return {
      search: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Mail/getMailMessages");
      // v.$store.dispatch("Mail/getUsers");
    });
  },
  computed: {
    messages() {
      return this.$store.getters["Mail/mailGetters"]("mailmessages");
    },
    // clients: function () {
    //   return this.$store.getters["Mail/mailGetters"]("users");
    // },
    headers() {
      return [
        {
          text: "From",
          value: "from",
        },
        {
          text: "To",
          value: "to",
        },
        {
          text: "Subject",
          value: "subject",
        },
        {
          text: "Body",
          value: "body",
        },
        {
          text: "Created At",
          value: "created_at",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    compose() {
      this.$router.push({ name: "MailCard" });
    },
    refreshMessages() {
      this.$store.dispatch("Mail/getMailMessages");
    },
    formatDate(date) {
      return helper.getDateFormat(date);
    },
    view(item) {
      this.$router.push({ name: "MailCard", params: { id: item.id } });
    },
  },
};
</script>
