export default {
  methods: {
    reply(item) {
      this.$router.push({
        name: "MailCard",
        params: { id: item.id },
        query: { reply: true },
      });
    },
    forward(item) {
      this.$router.push({
        name: "MailCard",
        params: { id: item.id },
        query: { forward: true },
      });
    },
    deleteMessage(item) {
      this.$confirm.show({
        title: "Delete Message",
        text: "Are you sure you want to delete this message?",
        onConfirm: () => {
          this.$store.dispatch("Mail/deleteMailMessage", { ...item });
        }
      });
    },
  },
};
