<template>
  <div>
    <router-view name="view" />
    <router-view name="action" />
  </div>
</template>
<script>
export default {
  name: "Mail",
};
</script>
