<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog v-model="dialog" persistent max-width="300">
    <v-card>
      <v-list>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in listItems" :key="i">
            <div class="d-flex" @click="updatechildIssue(item.code)">
              <v-list-item-icon>
                <div>
                  <v-avatar
                    class="white--text"
                    :size="28"
                    :color="item.code !== '' ? userColor(item.code) : 'primary'"
                  >
                    {{ item.code }}
                  </v-avatar>
                </div>
              </v-list-item-icon>
              <v-list-item-content class="ml-n3">
                {{ item.name }}
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text v-on:click="setDialog = false">
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import issueMixin from "../issueMixin";
export default {
  name: "AssignChildIssue",
  mixins: [issueMixin],
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    selectedDialog: {
      type: Object,
      required: true,
    },
    listItems: {
      type: Array,
      required: true,
    },
  },

  data: function () {
    return {};
  },

  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    // getSelectedAssignee: function () {
    //   const data = this.listItems[`${this.selectedItem}`].code;
    //   return data;
    // },
  },

  methods: {
    updatechildIssue(item) {
      const val = this.selectedDialog;
      const data = {
        assignee: item,
        description: val.description,
        dueDate: val.dueDate,
        lineNo: val.lineNo,
        parentIssue: val.parentIssue,
        projectCode: val.projectCode,
        status: val.status,
      };
      this.$store.dispatch("Ticket/updateChildIssue", data);
      setTimeout(() => (this.setDialog = false), 2000);
    },
  },
};
</script>
