<template>
  <v-scroll-y-transition leave-absolute>
    <div flat class="ml-n4">
      <v-toolbar flat height="50%">
        <span
          class="text-button font-weight-bold"
          v-if="issue.childIssues.length > 0"
          >Child Issues</span
        >
        <span
          class="text-body-1 font-weight-bold"
          v-if="issue.childIssues.length === 0 && addChildIssue"
          >Add Child Issue</span
        >
        <v-spacer />
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="issue.childIssues.length !== 0 && !addChildIssue"
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
              small
              @click="addChildIssue = true"
            >
              <v-icon>fa-solid fa-plus</v-icon>
            </v-btn>
          </template>
          <span>create new child issue</span>
        </v-tooltip>
      </v-toolbar>
      <v-list dense class="mt-n4">
        <v-list-item dense v-for="(issue, i) in issue.childIssues" :key="i">
          <v-toolbar dense elevation="1" class="mt-1">
            <div class="text-center" @click="sendInfo(issue)">
              <v-avatar
                class="mr-2 white--text"
                :size="30"
                :color="
                  issue.assignee !== '' ? userColor(issue.assignee) : 'grey'
                "
              >
                <span v-if="issue.assignee === ''"
                  ><v-icon>fa-solid fa-user</v-icon></span
                >
                <span v-else>{{ issue.assignee }}</span>
              </v-avatar>
            </div>

            <span
              @click="show(issue)"
              class=""
              v-html="
                issue.description !== '' ? issue.description : 'No Description'
              "
            >
            </span>

            <v-spacer />

            <v-menu offset-y @input="updateMenu(issue)">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  small
                  label
                  v-bind="attrs"
                  v-on="on"
                  class="ma-2"
                  :color="`${issueStatusColor(
                    issue.status ? issue.status.toUpperCase() : 'NONE'
                  )} lighten-5`"
                  :class="`${issueStatusColor(
                    issue.status ? issue.status.toUpperCase() : 'NONE'
                  )}--text text--darken-4 text-capitlize`"
                  >{{ issue.status ? issue.status.toUpperCase() : "NONE" }}
                  <v-icon x-small color="black" class="ml-2"
                    >fa fa-angle-down</v-icon
                  ></v-chip
                >
              </template>
              <v-list>
                <v-list-item-group v-model="childStatus">
                  <v-list-item
                    v-for="(item, index) in statuses"
                    :key="index"
                    link
                    @click="updateChildStatus(item.description)"
                  >
                    <v-list-item-title color="primary" class="font-weight-bold"
                      >{{ item.code }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="ml-4" dark v-bind="attrs" v-on="on">
                  <v-icon @click="deletechildIssue(issue)">
                    fa fa-trash
                  </v-icon></span
                >
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-toolbar>
        </v-list-item>
      </v-list>
      <v-list dense class="mt-n2" v-if="addChildIssue">
        <v-list-item dense class="add-issue-view">
          <v-list-item-content dense>
            <v-text-field
              v-if="!searchExistingIssue"
              outlined
              dense
              label="Issue Description"
              v-model="childIssueForm.description"
              placeholder="Enter Issue Description"
            ></v-text-field>
            <div dense v-if="searchExistingIssue">
              <v-menu v-model="menu" nudge-bottom="50">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-on="on"
                    v-bind="attrs"
                    v-model="issueSearch"
                    :readonly="selectedRelatedIssue"
                    label="Search by issue key or description"
                    single-line
                    dense
                    filled
                    clearable
                    @click:clear="clearSelectedRelatedIssue"
                    class="issue-text-field"
                    placeholder="Search by issue key or description"
                  >
                  </v-text-field>
                </template>
                <v-list dense>
                  <v-list-item dense v-if="filteredIssues.length > 0">
                    <v-list-item-subtitle>Matching Issues</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item-group
                    v-model="selectedRelatedIssue"
                    v-if="filteredIssues.length > 0"
                  >
                    <v-list-item
                      v-for="(issue, i) in filteredIssues"
                      :key="i"
                      dense
                      :value="issue"
                      class="item--active"
                    >
                      <v-list-item-title class="text-capitalize">
                        <div class="d-flex flex-row">
                          <span class="text-capitalize">
                            {{ issue.issueCode }}
                          </span>
                          <span class="ml-2" v-html="issue.description"> </span>
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                  <v-list-item-group
                    v-else-if="
                      issueSearch.length > 0 &&
                      filteredIssues.length === 0 &&
                      !this.selectedRelatedIssue
                    "
                    v-model="manualChildIssue"
                  >
                    <v-list-item dense class="item--active">
                      <v-list-item-title class="text-capitalize primary--text">
                        Add child issue from value {{ issueSearch }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                  <v-list-item-group v-else>
                    <v-list-item dense>
                      <v-list-item-title class="text-capitalize">
                        <div class="d-flex flex-column">
                          <div class="align-self-center">No issues found</div>
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
            <div
              :class="`d-flex mt-n4 ${
                searchExistingIssue ? 'flex-row-reverse' : ''
              }`"
            >
              <v-btn
                text
                class="text-capitalize text-body-1 pa-2 mr-auto"
                tile
                small
                v-if="!searchExistingIssue"
                @click="searchExisting"
              >
                <v-icon class="mx-2">fa-solid fa-magnifying-glass</v-icon>
                Choose an existing issue
              </v-btn>
              <v-btn
                v-if="!searchExistingIssue"
                class="text-capitalize pa-2 mx-2 text-body-1"
                color="primary"
                :disabled="childIssueForm.description.length === 0"
                small
                @click="createChildIssue"
              >
                Create
              </v-btn>
              <v-btn
                v-if="!searchExistingIssue"
                class="text-capitalize pa-2 text-body-1"
                color="deep-orange"
                @click="addChildIssue = false"
                small
                text
              >
                Cancel
              </v-btn>

              <!-- Buttons fo creating issue on search -->
              <v-btn
                v-if="searchExistingIssue"
                class="text-capitalize pa-2 text-body-1"
                color="deep-orange"
                @click="addChildIssue = false"
                small
                text
              >
                Cancel
              </v-btn>
              <v-btn
                v-if="searchExistingIssue"
                class="text-capitalize pa-2 mx-2 text-body-1"
                color="primary"
                :disabled="childIssueForm.description.length === 0"
                small
                @click="deleteCreateChildIssue()"
              >
                Create
              </v-btn>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <assign-child-issue
        :selectedDialog="selectedDialog"
        :dialog="dialog"
        :listItems="list"
        v-on:close-dialog="closeDialog"
      />
      <edit-child-issue
        :commentData="commentData"
        :dialog="editDialog"
        v-on:close-dialog="closeDialog"
      />
    </div>
  </v-scroll-y-transition>
</template>
<script>
import issueMixin from "../issueMixin";
import AssignChildIssue from "./AssignChildIssue.vue";
import EditChildIssue from "./EditChildIssue.vue";
export default {
  name: "ChildIssues",
  mixins: [issueMixin],

  components: {
    AssignChildIssue,
    EditChildIssue,
  },
  props: {
    issue: {
      type: Object,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    displayChildForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      childIssueForm: {
        description: "",
        parentIssue: this.issue.issueCode,
        projectCode: "",
        dueDate: "",
        priorities: "",
        tags: "CHILD",
        relatedIssue: "",
      },
      searchExistingIssue: false,
      issueSearch: "",
      menu: false,
      selectedRelatedIssue: null,
      manualChildIssue: null,
      selectedItem: "",
      dialog: false,
      selectedDialog: {},
      childStatus: "",
      UpdateMenu: {},
      toggle: false,
      showEditTextField: false,
      showButtons: false,
      childcomment: "",
      commentData: {},
      addComment: "",
      editDialog: false,
    };
  },
  computed: {
    hasChildIssues() {
      return this.issue.childIssues.length > 0;
    },
    statuses: function () {
      return this.$store.getters["Setup/setupGetters"]("status");
    },
    addChildIssue: {
      get() {
        return this.displayChildForm;
      },
      set(val) {
        this.$emit("closeChildIssue", val);
      },
    },
    filteredIssues() {
      return this.issueSearch.length > 0 && !this.selectedRelatedIssue
        ? this.$store.getters["Ticket/issueGetters"]("tickets").filter(
            (issue) => {
              return (
                (issue.issueCode
                  .toLowerCase()
                  .includes(this.issueSearch.toLowerCase()) ||
                  issue.description
                    .toLowerCase()
                    .includes(this.issueSearch.toLowerCase())) &&
                issue.projectCode === this.issue.projectCode &&
                issue.issueCode !== this.issue.issueCode &&
                !issue.childIssues
              );
            }
          )
        : [];
    },
    getSelectedStatus: function () {
      const data = this.statuses[`${this.childStatus}`].description;
      return data;
    },
  },
  methods: {
    createChildIssue() {
      this.childIssueForm.projectCode = this.issue.projectCode;
      this.childIssueForm.dueDate = this.issue.dueDate;
      this.childIssueForm.priorities = this.issue.priorities;
      this.childIssueForm.relatedIssue = this.issue.issueCode;
      const parentIssue = this.issue.issueCode;
      this.addChildIssue = false;
      this.$store.dispatch("Ticket/addChildIssue", {
        data: this.childIssueForm,
        parentIssue,
      });
    },
    addManually() {
      this.menu = false;
      this.searchExistingIssue = false;
      this.childIssueForm.description = this.issueSearch;
      this.addChildIssue = true;
    },
    searchExisting() {
      this.searchExistingIssue = true;
    },
    clearSelectedRelatedIssue() {
      this.selectedRelatedIssue = null;
      this.issueSearch = "";
      this.clearForm();
    },
    clearForm() {
      this.childIssueForm = {
        description: "",

        projectCode: "",
        dueDate: "",
        priorities: "",
        tags: "CHILD",
        relatedIssue: "",
      };
    },

    sendInfo(item) {
      this.dialog = true;
      this.selectedDialog = item;
      // alert("noma");
    },
    closeDialog: function (val) {
      this.dialog = val;
      this.editDialog = val;
    },
    updateMenu(val) {
      this.UpdateMenu = val;
    },
    updateChildStatus(item) {
      const data = {
        assignee: this.UpdateMenu.assignee,
        description: this.UpdateMenu.description,
        dueDate: this.UpdateMenu.dueDate,
        lineNo: this.UpdateMenu.lineNo,
        parentIssue: this.UpdateMenu.parentIssue,
        projectCode: this.UpdateMenu.projectCode,
        status: item,
      };
      console.log(data);
      this.$store.dispatch("Ticket/updateChildIssue", data);
    },
    deletechildIssue(issue) {
      const data = {
        lineNo: issue.lineNo,
        parentIssue: issue.parentIssue,
        projectCode: issue.projectCode,
      };
      this.$store.dispatch("Ticket/deleteChildIssue", data);
    },
    show(item) {
      console.log(item);
      this.commentData = item;
      this.editDialog = true;
    },
    deleteCreateChildIssue() {
      const data = {
        description: this.selectedRelatedIssue.description,
        parentIssue: this.issue.issueCode,
        projectCode: this.selectedRelatedIssue.projectCode,
        dueDate: this.selectedRelatedIssue.dueDate,
        priorities: this.selectedRelatedIssue.priorities,
        tags: "CHILD",
        relatedIssue: this.issue.issueCode,
      };
      const allData = {
        delData: this.selectedRelatedIssue,
        childData: data,
      };
      this.addChildIssue = false;

      this.$store.dispatch("Ticket/deleteParentIssueList", allData);
    },
  },
  watch: {
    selectedRelatedIssue: {
      handler(val) {
        if (val) {
          this.childIssueForm = val;
          this.menu = false;
          this.issueSearch = val.description;
        }
      },
      deep: true,
    },
    manualChildIssue: {
      handler(val) {
        if (val) {
          this.addChildIssue = true;
          this.searchExistingIssue = false;
          this.childIssueForm.description = this.issueSearch;
          this.issueSearch = "";
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="sass">
@import '~@/packages/tickets/modules/issues/styles/childIssue.sass'
</style>
