import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticClass:"fill-height",attrs:{"app":"","prominent":"","left":"","mini-variant":_vm.$vuetify.breakpoint.mdAndUp ? _vm.mini : false,"color":"drawerBackground","elevation":"0"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"d-flex justify-center mt-3"},[_c(VAvatar,{attrs:{"size":_vm.mini ? '40' : '80'}},[_c('Logo',{attrs:{"width":'100%',"height":'100%'}})],1)],1),_c(VList,{attrs:{"flat":""}},_vm._l((_vm.links),function(item,i){return _c('div',{key:i},[(item.sublinks)?_c(VListGroup,{staticClass:"white--text",scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(item.title))])]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},[_c(VListItemGroup,{staticClass:"white--text",attrs:{"color":"topbar","active-class":"drawer--active"}},_vm._l((item.sublinks),function(sub,s){return _c(VListItem,{key:s,staticClass:"white--text",attrs:{"to":sub.to}},[_c(VListItemIcon,{class:!_vm.mini ? 'mr-2 ml-6' : 'mr-2'},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(sub.icon))])],1),_c(VListItemTitle,[_vm._v(" "+_vm._s(sub.title)+" ")])],1)}),1)],1):_vm._e(),(!item.sublinks)?_c(VListItemGroup,{staticClass:"white--text",attrs:{"color":"topbar","active-class":"drawer--active"}},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({staticClass:"white--text",attrs:{"to":item.to}},'v-list-item',attrs,false),on),[_c(VListItemIcon,[_c(VIcon,{attrs:{"color":"white"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.title)+" ")])])],1):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }