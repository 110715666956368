export default {
  projectIssues: "setups/projectIssuesApi",
  saveIssue: "tickets/addIssue",
  patchIssue: (id) => `tickets/update/${id}`,
  assignIssue: "tickets/assignIssue",
  tickets: "tickets",
  allComments: "comments/getComments",
  addComment: "comments/addComment",
  UPLOAD_EXCEL: "tickets/excel",
  EXCEL_TEMPLATE: "tickets/excel/template",
  VALID_STATUSES: "tickets/validStatuses",
  ISSUE: (id) => `tickets/${id}`,
  ADD_CHILD_ISSUE: "tickets/childIssues",
  UPDATE_CHILD_ISSUE: "tickets/childIssues/update",
  UPDATE_STATUS: "tickets/updateStatus",
  DELETE_CHILD_ISSUE: "tickets/childIssues/delete",
  UPDATE_COMMENT: "tickets/issueComments/update",
  DELETE_COMMENT: "tickets/issueComments/delete",
  SAVE_COMMENT: "tickets/issueComments",
  DELETE_ATTACHMENT: "tickets/attachments/delete",
  UPDATE_ATTACHMENT: "tickets/attachments",
  FILTER_ISSUES: "tickets/customFilter",
  DELETE_ISSUE: "tickets/delete",
  CREATE_FROM_LIST: "tickets/childIssues",
};
