export default {
  // to: "/user-management/system/users",
  // order: 2,
  // icon: "fa fa-users",
  // title: "User Management",
  // disabled: false,
  // access: "SUPER_ADMIN",
  // sublinks: [
  //   {
  //     to: "/user-management/system/users",
  //     order: 1,
  //     icon: "fa fa-user",
  //     title: "System Users",
  //     disabled: false,
  //   },
  // ],
};
