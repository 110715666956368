import socketStore from "./socketStore";
// import io from "socket.io-client";
// import VueSocketIOExt from "vue-socket.io-extended";
// import { socketBaseUrl } from "@/packages/dashboard/environment";

export default {
  install(Vue, options) {
    /** socket IO */
    // const SocketInstance = io(socketBaseUrl, {
    //   withCredentials: false,
    // });

    // Vue.use(VueSocketIOExt, SocketInstance, { store: options.store });

    if (options.store) {
      options.store.registerModule("Socket", socketStore);
    }
  },
};
