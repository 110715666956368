import accessControlRoutes from "@/packages/access-control/accessControlRoutes";
import AccessControlStore from "@/packages/access-control/accessControlStore";
import AccessControlLinks from "@/packages/access-control/accessControlLinks";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoute(...accessControlRoutes);
    }
    if (options.store) {
      options.store.registerModule("AccessControl", AccessControlStore);
      options.store.dispatch("Dashboard/setLinks", AccessControlLinks);
    }
  },
};
