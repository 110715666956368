<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        v-on="on"
        v-bind="attrs"
        :class="(customClass ? customClass : '', 'cursor')"
        :color="GetColour(avatar ? avatar.email : 'N/A')"
        :size="size"
        elevation="3"
      >
        <span class="white--text">
          {{ GetInitials(avatar.email) }}
        </span>
      </v-avatar>
    </template>
    <span>{{ avatar.username }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "ProfileAvatar",
  props: {
    avatar: {
      default: () => {},
      type: Object,
    },
    customClass: {
      default: "",
      type: String,
    },
    size: {
      default: "35px",
      type: String,
    },
  },
  components: {},
  data: function () {
    return {
      selectAvatar: false,
    };
  },
  computed: {},
  methods: {
    GetColour(name) {
      var hash = 0;
      if (name.length === 0) return hash;
      for (var i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
      }
      var color = "#";
      for (var c = 0; c < 3; c++) {
        var value = (hash >> (c * 8)) & 255;
        color += ("00" + value.toString(16)).substr(-2);
      }
      return color;
    },
    GetInitials(name) {
      var initials = name.match(/\b\w/g) || [];
      initials = (
        (initials.shift() || "") + (initials.pop() || "")
      ).toUpperCase();
      return initials;
    },
  },
};
</script>
<style>
.cursor {
  cursor: pointer;
}
</style>
