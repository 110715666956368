import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFound from "@/components/PageNotFound";
import Forbidden from "@/shared/403-Forbidden";
import Auth from "@/packages/dashboard/modules/auth/middleware/Auth";
import DashboardLayout from "@/packages/dashboard/modules/dashboard/views/dashboardLayout";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/dashboard/main",
  },
  {
    path: "*",
    component: PageNotFound,
  },
  {
    path: "/access-forbidden",
    component: DashboardLayout,
    children: [
      {
        path: "403-access-forbidden",
        name: "PermissionForbidden",
        component: Forbidden,
        meta: {
          middleware: Auth,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_APP_URL,
  routes,
});

export default router;
