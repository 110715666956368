import call from "@/service/http";
import mailConstants from "./mailConstants";

export default {
  namespaced: true,
  state: {
    mailmessages: [],
    mailmessage: {},
    users: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    mailGetters: (state) => (val) => state[val],
  },
  actions: {
    getMailMessages: ({ commit }, payload) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", mailConstants.messages, payload)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "mailmessages", data: res.data.data });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getUsers: ({ commit }) => {
      call("get", mailConstants.users)
        .then((res) => {
          commit("MUTATE", { state: "users", data: res.data.data });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    sendMail: ({ commit }, payload) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", mailConstants.sendMail, payload)
        .then(() => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Mail Sent Successfully");
          Event.$emit("back");
        })
        .catch((error) => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getMessage: ({ commit }, payload) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("get", mailConstants.message(payload))
        .then((res) => {
          commit("MUTATE", { state: "mailmessage", data: res.data.data });
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    deleteMailMessage: ({ commit, dispatch }, payload) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", mailConstants.deleteMessage, payload)
        .then(() => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiSuccess", "Mail Deleted Successfully");
          dispatch("getMailMessages");
        })
        .catch((error) => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    readMessage: ({ commit }, payload) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", mailConstants.readMessage, payload)
        .then(() => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
  },
};
