import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VList,[_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.listItems),function(item,i){return _c(VListItem,{key:i},[_c('div',{staticClass:"d-flex",on:{"click":function($event){return _vm.updatechildIssue(item.code)}}},[_c(VListItemIcon,[_c('div',[_c(VAvatar,{staticClass:"white--text",attrs:{"size":28,"color":item.code !== '' ? _vm.userColor(item.code) : 'primary'}},[_vm._v(" "+_vm._s(item.code)+" ")])],1)]),_c(VListItemContent,{staticClass:"ml-n3"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)])}),1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.setDialog = false}}},[_vm._v(" close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }