export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    ticketCreated({ commit }, payload) {
      commit("Ticket/CREATE_TICKET_SOCKET", payload, { root: true });
    },
    ticketUpdated({ commit }, payload) {
      commit("Ticket/UPDATE_TICKET_SOCKET", payload, { root: true });
    },
    ticketSocket({ commit }, payload) {
      switch (payload.event) {
        case "addticket:event":
          commit("Ticket/CREATE_TICKET_SOCKET", payload.data, { root: true });
          break;
        case "updateticket:event":
          commit("Ticket/UPDATE_TICKET_SOCKET", payload.data, { root: true });
          break;

        default:
          break;
      }
    },
  },
};
