<template>
  <div>
    <div class="mt-2">
      Add Comment
      <v-col>
        <v-text-field
          class="mt-0"
          v-model="addComment"
          outlined
          dense
          v-on:focus="showButtons = true"
          clearable
        ></v-text-field>

        <div
          class="d-flex flex-row justify-right mt-n2"
          :class="Comments.issueComments.length !== 0 ? 'mb-n1' : 'mb-5'"
          v-if="showButtons"
        >
          <!-- <v-btn
            class="text-overline"
            x-small
            color="primary"
            @click="SaveComment()"
          >
            Save
          </v-btn>
          <v-btn
            class="text-overline"
            x-small
            text
            color="error"
            @click="showButtons = false"
          >
            Cancel
          </v-btn> -->
          <v-spacer />

          <v-icon
            :size="20"
            class="mr-3"
            color="primary"
            @click="SaveComment()"
          >
            fa fa-check
          </v-icon>

          <v-icon
            class="ml-2"
            :size="20"
            color="error"
            @click="showButtons = false"
          >
            fa fa-ban
          </v-icon>
        </div>
      </v-col>
    </div>
    <v-card flat class="overflow-auto" height="150">
      <div v-if="!showEditTextField">
        <v-list>
          <v-list-item-title
            class="text-overline font-weight-bold mt-n3"
            v-if="Comments.issueComments.length !== 0"
            >Comments</v-list-item-title
          >
          <v-list-item v-for="(item, i) in Comments.issueComments" :key="i">
            <v-list-item-content cols="12" v-if="!showEditTextField">
              <v-list-item-title>
                <div class="d-flex">
                  <v-avatar
                    class="white--text"
                    :size="28"
                    :color="item.user !== '' ? userColor(item.user) : 'primary'"
                  >
                    <span v-if="item.user === ''"
                      ><v-icon>fa-solid fa-user</v-icon></span
                    >
                    <span v-else>{{ item.user }}</span>
                  </v-avatar>

                  <span class="mt-1 ml-2" v-html="item.comment"></span>
                </div>
              </v-list-item-title>

              <v-list-item-subtitle class="ml-4" v-if="forbidUsertoEdit(item)">
                <span
                  class="ml-4"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="show(item)"
                >
                  <v-icon> fa fa-pencil </v-icon>
                  Edit</span
                >
                <span
                  class="ml-4"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteComment(item)"
                >
                  <v-icon> fa fa-trash </v-icon>
                  Delete</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div v-if="showEditTextField">
        <span class="mt-3">Edit Comment</span>

        <v-col cols="8">
          <v-text-field
            class="mr-3"
            v-model="comment"
            outlined
            filled
            dense
            clearable
          ></v-text-field>
          <div class="d-flex flex-row justify-right mt-n2">
            <v-btn
              class="pa2 mr-2 text-body-2"
              x-small
              color="primary"
              @click="updateComent()"
            >
              Save
            </v-btn>
            <v-btn
              class="pa-2 text-body-2"
              x-small
              text
              color="red"
              @click="showEditTextField = false"
            >
              Cancel
            </v-btn>
          </div>
        </v-col>
      </div>
    </v-card>
  </div>
</template>
<script>
import issueMixin from "../issueMixin";
import { AuthService } from "@/packages/dashboard/modules/auth";
export default {
  name: "IssueComments",
  mixins: [issueMixin],
  props: {
    Comments: Object,
  },
  data: function () {
    return {
      showEditTextField: false,
      showButtons: false,
      comment: "",
      commentData: {},
      addComment: "",
    };
  },
  computed: {
    user() {
      return AuthService.user;
    },
  },
  methods: {
    show(item) {
      this.showEditTextField = true;
      this.comment = item.comment;
      this.commentData = item;
    },

    deleteComment(item) {
      const data = {
        projectCode: item.projectCode,
        childIssue: item.childIssue,
        comment: item.comment,
        entryNo: item.entryNo,
        issue: item.issue,
        user: item.user,
      };
      this.$store.dispatch("Ticket/deleteComment", data);
    },
    updateComent() {
      const data = {
        projectCode: this.commentData.projectCode,
        childIssue: this.commentData.childIssue,
        comment: this.comment,
        entryNo: this.commentData.entryNo,
        issue: this.commentData.issue,
        user: this.commentData.user,
      };
      this.$store.dispatch("Ticket/updateComment", data);
      this.showEditTextField = false;
    },
    SaveComment() {
      const data = {
        comment: this.addComment,
        issue: this.Comments.issueCode,
        projectCode: this.Comments.projectCode,
        user: this.user.user_code,
      };
      this.$store.dispatch("Ticket/saveComment", data);
      this.showButtons = false;
      this.addComment = "";
    },
    forbidUsertoEdit(item) {
      if (item.user !== this.user.user_code) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    Comments: {
      handler: function () {
        this.comment = this.Comments.comment;
        // this.date = this.selectedIssue.dueDate;
      },
    },
  },
};
</script>
