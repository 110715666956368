import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 lighten-2"},[_vm._v(" Preview "),_c(VSpacer,[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-3 white--text",attrs:{"color":"teal darken-4","label":"","small":"","dense":""},on:{"click":function($event){return _vm.downloadLink(("" + _vm.url + (_vm.imagetoPreview.previewDoc)))}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-download-circle")]),_vm._v("Download ")],1)]}}])},[_c('span',[_vm._v("Download File")])])],1),_c(VCardActions,[_c(VIcon,{attrs:{"color":"error","size":"30"},on:{"click":function($event){_vm.setDialog = false}}},[_vm._v("fa fa-times")])],1)],1),(_vm.imagetoPreview.Extension === 'pdf')?_c('div',_vm._l((_vm.getPages(("" + _vm.url + (_vm.imagetoPreview.previewDoc)))),function(i){return _c('pdf',{key:i,attrs:{"src":("" + _vm.url + (_vm.imagetoPreview.previewDoc)),"page":i}})}),1):_vm._e(),(_vm.imagetoPreview.Extension !== 'pdf')?_c('div',[_c(VImg,{attrs:{"alt":"","src":("" + _vm.url + (_vm.imagetoPreview.previewDoc))}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }