import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,{attrs:{"id":"inspire"}},[_c(VMain,{staticClass:"main--landing"},[_c(VRow,[_c(VCol,{staticClass:"slider",attrs:{"cols":"5"}},[_c(VImg,{attrs:{"src":require("../../../assets/cycle.png"),"height":"100%"}})],1),_c(VCol,{attrs:{"cols":"7"}},[_c(VCol,[_c(VCard,{staticClass:"mx-auto my-auto",attrs:{"flat":""}},[_c(VCardText,[_c('Login',{staticClass:"pt-n4"})],1)],1)],1)],1)],1)],1),_c('spinner'),_c('OtpVerification')],1)}
var staticRenderFns = []

export { render, staticRenderFns }