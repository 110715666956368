<template>
  <router-view />
</template>

<script>
import accessControlActionLinks from "@/packages/access-control/accessControlActionLinks";

export default {
  name: "AccessControl",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Dashboard/setToolBarLinks", accessControlActionLinks);
    });
  },
};
</script>

<style scoped></style>
