<template>
  <!-- eslint-disable vue/valid-v-slot -->
  <v-container fluid>
    <v-card flat color="transparent" class="mx-auto">
      <v-row>
        <v-col cols="12" md="3">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="6" md="4">
                  <v-avatar size="50" color="indigo" class="mx-auto">
                    <v-icon color="white">fa fa-folder-open</v-icon>
                  </v-avatar>
                </v-col>
                <v-col class="d-flex flex-column" cols="6" md="8">
                  <span class="text-h4 primary--text mx-auto">
                    {{ projects ? projects.length : 0 }}
                  </span>
                  <span class="text-h7 ma-auto text-uppercase">project</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="6" md="4">
                  <v-avatar size="50" color="pink" class="mx-auto">
                    <v-icon color="white">fa fa-ticket</v-icon>
                  </v-avatar>
                </v-col>
                <v-col class="d-flex flex-column" cols="6" md="8">
                  <span class="text-h4 primary--text mx-auto">
                    {{ issues ? issues.length : "0" }}
                  </span>
                  <span class="text-h7 ma-auto text-uppercase">tickets</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="6" md="4">
                  <v-avatar size="50" color="green" class="mx-auto">
                    <v-icon color="white">fa fa-check</v-icon>
                  </v-avatar>
                </v-col>

                <v-col class="d-flex flex-column" cols="6" md="8">
                  <span class="text-h4 primary--text mx-auto">
                    {{ completedIssues ? completedIssues.length : "0" }}
                  </span>
                  <span class="text-h7 ma-auto text-uppercase">closed</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="6" md="4">
                  <v-avatar size="50" color="orange " class="mx-auto">
                    <v-icon color="white">fa fa-list</v-icon>
                  </v-avatar>
                </v-col>
                <v-col class="d-flex flex-column" cols="6" md="8">
                  <span class="text-h4 primary--text mx-auto">
                    {{ unAssignedIssues ? unAssignedIssues.length : "0" }}
                  </span>
                  <span class="text-h7 ma-auto text-uppercase">
                    Unassigned Issues
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mx-auto mt-2">
      <v-card-title class="mt-n3">
        <span class="text-lg-h4">Issues </span>
        <v-text-field
          prepend-inner-icon="fa-light fa-magnifying-glass"
          placeholder="Free text search"
          class="mx-2 mt-3"
          dense
          v-model="search"
        ></v-text-field>
        <v-spacer />

        <v-btn-toggle
          color="primary"
          class="mx-2"
          borderless
          v-model="ticketType"
          dense
        >
          <v-btn value="all"> All Issues </v-btn>
          <v-btn value="unassigned"> Unassigned </v-btn>
        </v-btn-toggle>
        <v-btn color="primary white--text" @click="addIssue()">
          <v-icon class="mr-2" size="25"> fa fa-plus </v-icon>
          Add Issue
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-responsive class="mx-auto" max-width="1368px">
          <ProjectsFilter @changeTitle="ChangeT($event)" class="mb-n4" />

          <v-data-table
            :headers="headers"
            :items="assignedOrUnassigned"
            :search="search"
            @dblclick:row="selectIssue"
            fixed-header
            height="70vh"
          >
            <template v-slot:item.issueType="{ item }">
              <v-chip
                label
                small
                :color="`${issueTypeColor(
                  item.issueType.toLowerCase()
                )} lighten-5`"
                :class="`${issueTypeColor(
                  item.issueType.toLowerCase()
                )}--text text--darken-4 text-capitalize`"
                >{{ item.issueType }}</v-chip
              >
            </template>
            <template v-slot:item.title="{ item }">
              <a @click="selectIssue($event, item)">
                <span
                  :class="`${issueTypeColor(
                    item.issueType
                  )}--text text--darken-4 text-capitlize`"
                  v-if="item.title"
                  v-html="
                    item.title.length > 40
                      ? `${item.title.slice(0, 40)}...`
                      : item.title
                  "
                />
              </a>
            </template>
            <template v-slot:item.assignee="{ item }">
              <div class="d-flex" v-if="item.assignee">
                <v-avatar
                  class="mr-2 white--text"
                  :size="24"
                  :color="
                    item.assignee !== '' ? userColor(item.assignee) : 'primary'
                  "
                >
                  <v-img
                    v-if="item.assignee"
                    :src="getAssigneeAvatar(item.assignee)"
                    contain
                  />
                </v-avatar>

                <span>{{ getAsigneeName(item.assignee) }}</span>
              </div>
              <div v-else>
                <span class="text-capitalize">Unassigned</span>
              </div>
            </template>
            <template v-slot:item.reporter="{ item }">
              <div class="d-flex">
                <v-avatar
                  class="mr-2 white--text"
                  :size="24"
                  :color="
                    item.reporter !== '' ? userColor(item.reporter) : 'primary'
                  "
                >
                  <v-img
                    v-if="item.reporter"
                    :src="getReporterAvatar(item.reporter)"
                    contain
                  />
                </v-avatar>

                <span>{{ getReporterName(item.reporter) }}</span>
              </div>
            </template>
            <template v-slot:item.priorities="{ item }">
              <v-chip
                label
                small
                :color="
                  item.priorities
                    ? `${issuePriorityColor(
                        item.priorities.toUpperCase()
                      )} lighten-5`
                    : ''
                "
                :class="
                  item.priorities
                    ? `${issuePriorityColor(
                        item.priorities.toUpperCase()
                      )}--text text--darken-4 text-capitlize`
                    : ''
                "
                >{{
                  item.priorities ? item.priorities.toUpperCase() : "None"
                }}</v-chip
              >
            </template>
            <template v-slot:item.status="{ item }">
              <v-chip
                label
                small
                :color="`${issueStatusColor(item.status)} lighten-5`"
                :class="`${issueStatusColor(
                  item.issueStatusColor
                )}--text text--darken-4 text-capitlize`"
              >
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:item.productModules="{ item }">
              {{ item.moduleCode }}
            </template>
            <template v-slot:item.dueDate="{ item }">
              {{ formatDate(item.dueDate) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="text-capitalize links"
                    text
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="selectIssue($event, item)"
                    ><span
                      ><v-icon color="secondary" size="">mdi-delete</v-icon>
                      view</span
                    ></v-list-item
                  >
                  <v-list-item @click="DELETEISSUE(item)"
                    ><span
                      ><v-icon color="secondary" size="">mdi-delete</v-icon>
                      delete</span
                    ></v-list-item
                  >
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-responsive>
      </v-card-text>
    </v-card>

    <audio id="audio" src="@/assets/deduction-588.mp3"></audio>

    <AddIssueVue />
  </v-container>
</template>
<script>
import ProjectsFilter from "./ProjectsFilter.vue";
import SetupMixin from "../../../../setup/SetupMixin";
import { AuthService } from "../../../../dashboard/modules/auth";
import issueListLinks from "../toolbarlinks/issueListLinks";
import issueMixin from "../issueMixin";
import SocketsMixin from "@/packages/dashboard/mixin/sockets/SocketsMixin";
import PushNotificationMixin from "@/packages/dashboard/mixin/sockets/PushNotificationMixin";
import Permissions from "@/shared/Permissions";
import AddIssueVue from "./AddIssue.vue";

export default {
  name: "IssueList",
  mixins: [SocketsMixin, PushNotificationMixin, SetupMixin, issueMixin],
  components: { ProjectsFilter, AddIssueVue },
  data: function () {
    return {
      PERMISSIONS: Permissions,
      toggle: false,
      ticketType: "all",
      loading: false,
      filteredIssues: [],
      dialog: false,
      selectedIssue: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Dashboard/setToolBarLinks", issueListLinks);
      v.$store.dispatch("Ticket/getIssues");
      v.$store.dispatch("Setup/getissueTypes");
      v.$store.dispatch("Setup/getProjects");
      v.$store.dispatch("Setup/getIssuePriorities");
      v.$store.dispatch("Setup/getIssueStatus");
      v.$store.dispatch("Setup/getModules");
      v.$store.dispatch("Setup/getAssignees");
      v.$store.dispatch("Setup/getReporters");
    });
  },
  mounted() {},
  computed: {
    headers: function () {
      return [
        {
          text: "Type",
          value: "issueType",
          align: "start",
          sortable: true,
        },
        {
          text: "No.",
          value: "issueCode",
          align: "start",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          align: "start",
          sortable: false,
        },
        {
          text: "Assignee",
          value: "assignee",
          align: "start",
          sortable: true,
        },
        {
          text: "Reporter",
          value: "reporter",
          align: "start",
          sortable: true,
        },
        {
          text: "Requested By",
          value: "requestedBy",
          align: "start",
          sortable: true,
        },
        {
          text: "Priority",
          value: "priorities",
          align: "start",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          align: "start",
          sortable: true,
        },
        {
          text: "Module",
          value: "productModules",
          align: "start",
          sortable: true,
        },
        {
          text: "DueDate",
          value: "dueDate",
          align: "start",
          sortable: true,
        },

        {
          text: "",
          value: "actions",
          align: "end",
          sortable: true,
        },
      ];
    },
    user() {
      return AuthService.user;
    },
    issues: function () {
      return this.$store.getters["Ticket/issueGetters"]("tickets");
    },
    agents: function () {
      return this.$store.getters["Setup/setupGetters"]("agents");
    },
    isAdmin: function () {
      return (
        this.user.user_type === "SUPER_ADMIN" ||
        this.user.user_type === "AGENT_ADMIN"
      );
    },
    comments: function () {
      return this.$store.getters["Ticket/issueGetters"]("allComments").filter(
        (d) => d.ticket === this.selectedIssue.id
      );
    },
    routeParams() {
      return this.$route.params.code;
    },
    issuesWithParams() {
      const list = this.issues.filter(
        (el) => el.projectCode === this.routeParams
      );

      return list;
    },
    unAssignedIssues: function () {
      return this.$store.getters["Ticket/issueGetters"]("tickets").filter(
        (el) => !el.assignee
      );
    },
    projects: function () {
      return this.$store.getters["Setup/setupGetters"]("projects");
    },
    completedIssues: function () {
      return this.$store.getters["Ticket/issueGetters"]("tickets").filter(
        (d) => {
          return (
            (d.assignee ? d.assignee === this.user.id : false) &&
            d.status === "DONE"
          );
        }
      ).length;
    },
    assignees: function () {
      return this.$store.getters["Setup/setupGetters"]("assignees");
    },
    reporters: function () {
      return this.$store.getters["Setup/setupGetters"]("reporters");
    },
    assignedOrUnassigned() {
      if (this.ticketType === "unassigned") {
        return this.unAssignedIssues;
      } else {
        return this.issues;
      }
    },
  },
  methods: {
    edit(item) {
      console.log("Editing", item);
      this.$router.push({
        name: "IssueCard",
        params: { code: item.issueCode },
      });
      this.Issue = item;
    },

    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    addIssue() {
      return this.$store.dispatch("Ticket/addIssue", true);
    },
    closeDialog: function (val) {
      this.dialog = val;
    },

    getReporterName(val) {
      if (val) {
        const data = this.reporters.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].name;
        } else {
          return "";
        }
      }
    },

    getReporterAvatar(val) {
      if (val) {
        const data = this.reporters.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].avatar
            ? `data:image/png;base64,${data[0].avatar}`
            : require("@/assets/avatar.jpeg");
        } else {
          return "";
        }
      }
    },

    getAsigneeName(val) {
      if (val) {
        const data = this.assignees.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].name;
        } else {
          return "";
        }
      }
    },
    formatDate(date) {
      if (date === "0001-01-01") return "N/A";
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      const val = [year, month, day].join("-");

      var today = new Date(val);
      var m = today.toLocaleString("default", { month: "short" });

      return [year, m, day].join("-");
    },
    DELETEISSUE(val) {
      this.$confirm.show({
        title: ` ${val.title} `,
        text: "Are you sure you want to delete this issue?",
        onConfirm: () => {
          this.$store.dispatch("Ticket/deleteParentIssue", val);
        },
        onCancel: () => {
          this.$store.dispatch("Ticket/getIssues");
        },
      });
    },
    getAssigneeAvatar(code) {
      const agent = this.assignees.filter((item) => item.code === code).shift();
      return agent ? process.env.VUE_APP_TEMP_URL + agent.avatar : "";
    },
  },
};
</script>
<style lang="sass">
@import '~@/packages/tickets/modules/issues/styles/issues.sass'
</style>
<style scoped>
a:hover {
  text-decoration: underline;
}
</style>
