<template>
  <v-app id="inspire">
    <v-main class="main--landing">
      <v-row>
        <v-col cols="5" class="slider">
          <v-img src="../../../assets/cycle.png" height="100%"> </v-img>
        </v-col>
        <v-col cols="7">
          <v-col>
            <v-card flat class="mx-auto my-auto">
              <v-card-text>
                <Login class="pt-n4" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-main>
    <spinner />
    <OtpVerification />
    <!--    <Footer opacity="0.65" />-->
  </v-app>
</template>

<script>
import { AuthService } from "../../auth";
import Spinner from "../../../plugins/loader/views/Spinner";
import OtpVerification from "../../../plugins/verify/OtpVerification.vue";
import Login from "../../auth/components/Login.vue";

export default {
  name: "Landing",

  data: function () {
    return {
      message: [
        "To Activate:",
        "Option 1: Get your student details by searching using your student Number/ID Number/Reg No./FullName/Surname e.g. CCT0000123 which will populate the neccessary fields below. then enter any blank fields (if there are any).",
        "Option 2: Enter blank fields below the search field then <strong> click on the ACTIVATE button </strong>",
        "Steps after clicking the <strong>ACTIVATE</strong> button:",
        [
          "An <strong> OTP code </strong> will be sent (via sms) to the Phone number and Email Entered.",
          "Enter the code on the Activation Code Field.",
          "Enter your password then confirm the passwrod your entered. (Passwords must match)",
          "Finally click on the <strong>SUBMIT</strong> button to activate.",
        ],
      ],
    };
  },
  components: {
    Spinner,
    OtpVerification,
    Login,
  },
  computed: {
    company() {
      return this.$store.getters["Landing/company"];
    },

    auth() {
      return AuthService;
    },

    activationResults() {
      return this.$store.getters["Activation/activationGetter"](
        "activationResults"
      );
    },

    items() {
      return [
        {
          name: "APPLICATIONS",
          icon: "mdi-account-check",
          description: "New student applications",
        },
        {
          name: "EXAMINATION",
          icon: "mdi-file-check",
          description: "Book for exams, download timetable and result slips",
        },
      ];
    },
  },
};
</script>

<style>
@import url("../../auth/styles.css");
</style>
