<template>
  <v-app-bar app elevation="0" color="topbar" height="50%">
    <v-btn icon color="topbarText" @click="closeDrawer">
      <v-icon>apps</v-icon>
    </v-btn>
    <div class="topbarText--text text-body-1 text-no-wrap">
      {{ company.displayName }} <span class="mx-2">|</span> Issue Management
    </div>
    <v-spacer />

    <v-badge
      top
      color="warning"
      offset-y="23"
      offset-x="20"
      class="mx-2"
      overlap
      :content="messages"
    >
      <v-btn icon link :to="{ name: 'mailList' }" elevation="0">
        <v-icon large color="secondary">mail</v-icon>
      </v-btn>
    </v-badge>

    <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bottom
          color="warning"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar size="40px" v-bind="attrs" v-on="on">
            <v-img
              :src="
                profile.avatar
                  ? profilePicture
                  : require('@/assets/avatar.jpeg')
              "
            ></v-img>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge
            bottom
            color="warning"
            overlap
            offset-x="12"
            offset-y="12"
            class="ms-4"
            dot
          >
            <v-avatar size="40px">
              <v-img
                :src="
                  profile.avatar
                    ? profilePicture
                    : require('@/assets/avatar.jpeg')
                "
              ></v-img>
            </v-avatar>
          </v-badge>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align: middle"
          >
            <span class="text--primary font-weight-semibold mb-n1">
              <span>{{
                profile
                  ? `${profile.first_name} ${""} ${profile.last_name}`
                  : ""
              }}</span>
            </span>
            <small class="text--disabled text-capitalize mt-1">{{
              profile ? profile.user_type : ""
            }}</small>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Profile -->
        <v-list-item link @click="editProfile()">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title
              ><v-btn text class="account-btn" @click="logout"
                ><v-icon size="22"> {{ icons.mdiLogoutVariant }}</v-icon
                >Logout</v-btn
              ></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { AuthService } from "../../../../../dashboard/modules/auth";
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from "@mdi/js";

export default {
  name: "AppBarTop",
  data: function () {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
      tabs: null,
      url: process.env.VUE_APP_TEMP_URL,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Mail/getMailMessages");
    });
  },
  computed: {
    profile() {
      return AuthService.user;
    },
    profilePicture() {
      return `${this.url}${"/storage/"}${this.profile.avatar}`;
    },
    company: function () {
      return this.$store.getters["Dashboard/companyInfo"];
    },
    mini() {
      return this.$store.getters["Dashboard/miniDrawer"];
    },
    messages() {
      const messages = this.$store.getters["Mail/mailGetters"]("mailmessages");
      return messages ? messages.filter((el) => !el.is_read).length : 0;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("Auth/logout");
    },
    editProfile() {
      this.$router.replace({
        name: "Profile",
      });
    },
    closeDrawer() {
      this.$store.dispatch("Dashboard/setMiniDrawer", !this.mini);
    },
  },
};
</script>
<style>
.v-app-bar-title__content {
  width: 300px !important;
}
</style>
