export default {
  // to: "/access-control/roles",
  // order: 2,
  // icon: "fa fa-lock",
  // title: "Access Control",
  // disabled: false,
  // access: "SUPER_ADMIN",
  // sublinks: [
  //   {
  //     to: "/access-control/roles",
  //     order: 1,
  //     icon: "fa fa-unlock-alt",
  //     title: "Roles",
  //     disabled: false,
  //   },
  //   {
  //     to: "/access-control/permissions",
  //     order: 2,
  //     icon: "fa fa-unlock",
  //     title: "Permissions",
  //     disabled: false,
  //   },
  // ],
};
