<template>
  <v-container>
    <v-card class="justify-center">
      <v-card-title>
        <v-btn @click="Back()" depressed color="teal darken-4 white--text">
          <v-icon class="mr-1">fa fa-arrow-left</v-icon> Back
        </v-btn>

        <v-spacer />
        My Profile
        <v-spacer />
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-tabs v-model="tab" class="px-16">
          <v-tab>Profile</v-tab>
          <v-tab>Password</v-tab>

          <v-tabs-items v-model="tab">
            <v-form ref="updateDetailsForm" v-model="isValid">
              <v-tab-item class="mt-5">
                <v-row>
                  <v-col cols="12" md="3" class="mt-n4">
                    <v-card-title>
                      <v-avatar color="primary" size="120" v-if="userDetails">
                        <v-img
                          v-model="formData.avatar"
                          :src="
                            profilePicture
                              ? profilePicture
                              : require('@/assets/avatar.jpeg')
                          "
                        />
                      </v-avatar>

                      <div
                        class="ml-n3"
                        @click="$refs.uploader.$refs.input.click()"
                      >
                        <v-avatar size="30" color="orange">
                          <v-icon size="15" color="white">
                            fa fa-pencil
                          </v-icon>
                        </v-avatar>
                      </div>

                      <span>{{
                        profile
                          ? `${profile.first_name} ${""} ${profile.last_name}`
                          : ""
                      }}</span>
                    </v-card-title>
                  </v-col>

                  <v-col cols="12" md="9">
                    <v-row>
                      <v-col cols="12" md="6" class="mt-8">
                        <v-text-field
                          v-model="formData.first_name"
                          filled
                          single-line
                          dense
                          label="First Name"
                          @keyup.enter="reset()"
                        />
                      </v-col>

                      <v-col cols="12" md="6" class="mt-8">
                        <v-text-field
                          v-model="formData.last_name"
                          filled
                          single-line
                          dense
                          label="Last Name"
                          @keyup.enter="reset()"
                        />
                      </v-col>

                      <v-col cols="12" md="6" class="mt-n4">
                        <v-text-field
                          v-model="formData.phone"
                          filled
                          single-line
                          dense
                          label="Phone Number"
                          append-icon="mdi-phone-classic"
                          :rules="rules.phoneNo"
                          @keyup.enter="reset()"
                        >
                          <template v-slot:append>
                            <v-btn icon>
                              <v-icon small> fa fa-phone </v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-tab-item class="mt-5">
                <v-row>
                  <v-col cols="12" class="mt-n4">
                    <v-text-field
                      v-model="passwordForm.current_password"
                      filled
                      dense
                      single-line
                      @copy.prevent
                      label=" Current Password"
                      :type="showPassword ? 'text' : 'password'"
                      :rules="rules.password"
                      autocomplete="false"
                      @keyup.enter="reset()"
                    >
                      <template v-slot:append>
                        <v-btn @click="showPassword = !showPassword" icon>
                          <v-icon small>
                            {{ showPassword ? "visibility" : "visibility_off" }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" class="mt-n4">
                    <v-text-field
                      v-model="passwordForm.new_password"
                      filled
                      dense
                      single-line
                      @copy.prevent
                      label=" New Password"
                      :type="showPassword ? 'text' : 'password'"
                      :rules="rules.password"
                      autocomplete="false"
                      @keyup.enter="reset()"
                    >
                      <template v-slot:append>
                        <v-btn @click="showPassword = !showPassword" icon>
                          <v-icon small>
                            {{ showPassword ? "visibility" : "visibility_off" }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" class="mt-n4">
                    <v-text-field
                      v-model="passwordForm.new_confirm_password"
                      filled
                      dense
                      single-line
                      @copy.prevent
                      label="Confirm New Password"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      :rules="rules.confirmPassword"
                      autocomplete="false"
                      @keyup.enter="reset()"
                    >
                      <template v-slot:append>
                        <v-btn
                          @click="showConfirmPassword = !showConfirmPassword"
                          icon
                        >
                          <v-icon small>
                            {{
                              showConfirmPassword
                                ? "visibility"
                                : "visibility_off"
                            }}
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>

              <v-file-input
                accept="*"
                ref="uploader"
                hide-input
                prepend-icon=""
                @change="uploadFile"
              />
            </v-form>
          </v-tabs-items>
        </v-tabs>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="px-16">
        <v-spacer />

        <v-btn color="primary accent-4 white--text" @click="updateUser()">
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
// import { helper } from "@/packages/dashboard/utils";
import helpers from "@/packages/dashboard/utils/helpers/helper.js";
import { AuthService } from "../../../../dashboard/modules/auth";
export default {
  name: "Profile",
  data() {
    return {
      url: process.env.VUE_APP_TEMP_URL,
      formData: {
        first_name: "",
        last_name: "",
        phone: "",
        avatar: "",
        id: "",
        current_password: "",
        new_password: "",
        new_confirm_password: "",
      },
      passwordForm: {
        current_password: "",
        new_password: "",
        new_confirm_password: "",
      },
      tab: 0,
      isValid: false,
      showPassword: false,
      showConfirmPassword: false,
      image_url: "",
      image: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Dashboard/getUser");
    });
  },
  computed: {
    profile() {
      return AuthService.user;
    },
    rules() {
      return {
        required: [(v) => !!v || "Field is required"],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#~^()`.,;:{}_/%*?&])[A-Za-z\d@$!#~^()`.,;:{}_/%*?&]{8,16}$/.test(
              v
            ) ||
            "Password should contain: Minimum 8 and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character",
        ],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) =>
            v === this.passwordForm.new_password || "Passwords do not match",
        ],
        phoneNo: [
          (v) => !!v || "Field is required",
          (v) =>
            !v ||
            /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
            "Invalid phone number format",
        ],
      };
    },
    project() {
      const projects = this.$store.getters["Setup/setupGetters"]("projects");
      return projects
        .filter((item) => {
          return item.code === this.$route.params.company;
        })
        .shift();
    },
    profilePicture() {
      if (this.image_url) return this.image_url;

      return this.userDetails
        ? this.userDetails.avatar
          ? `${this.url}/storage/${this.userDetails.avatar}`
          : "/img/avatar.jpg"
        : "/img/avatar.jpg";
    },
    userDetails() {
      return this.$store.getters["Dashboard/AuthUser"];
    },
  },
  methods: {
    updateUser: function () {
      if (!this.isValid) {
        this.$refs.updateDetailsForm.validate();
      } else {
        let data = {};
        if (this.tab === 0) {
          data = this.formData;
          data.avatar = this.image[0];
        } else {
          data = this.passwordForm;
        }
        data.id = this.userDetails.id;
        this.$store.dispatch("Dashboard/updateProfile", {
          sentData: helpers.prepareFormData(data),
          id: this.userDetails.id,
          passwordReset: this.tab === 1,
        });
      }
    },
    uploadFile(value) {
      if (value) {
        const data = this.$refs.uploader.$refs.input.files;
        this.image = data;
        this["image_url"] = URL.createObjectURL(data[0]);
      }
    },
    Back: function () {
      this.$router.replace({
        name: "issueList",
      });
    },
  },
  watch: {
    userDetails: {
      handler: function () {
        this.formData = { ...this.userDetails };
      },
    },
  },
};
</script>
