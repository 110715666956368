<template>
  <v-sheet>
    <v-slide-group show-arrows class="slider--body--prev slider--body--next">
      <v-slide-item
        v-for="(item, i) in filteredItems"
        :key="i"
        class="slider--item"
      >
        <v-autocomplete
          :key="i"
          v-model="item.selectedValues"
          :items="filterItems[item.label]"
          :item-text="(item) => item.description"
          :item-value="(item) => item.code"
          :placeholder="item.label"
          @change="filterSelected()"
          multiple
          outlined
          dense
          class="mx-2"
        >
          <template v-slot:selection="{ item, attrs }">
            <v-chip
              v-bind="attrs"
              :key="item.code"
              label
              small
              class="ma-1 primary"
            >
              {{ item.description }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>
<script>
import { AuthService } from "../../../../dashboard/modules/auth";

export default {
  name: "ProjectsFilter",
  data: function () {
    return {
      //
    };
  },
  computed: {
    user() {
      return AuthService.user;
    },
    // using
    filteredItems: function () {
      return [
        {
          label: "projects",
          field: "projectCode",
          selectedValues: [],
        },
        {
          label: "assignees",
          field: "assignee",
          selectedValues: [],
        },
        {
          label: "reporters",
          field: "reporter",
          selectedValues: [],
        },
        {
          label: "requested by",
          field: "requestedBy",
          selectedValues: [],
        },
        {
          label: "status",
          field: "status",
          selectedValues: [],
        },
        {
          label: "priorities",
          field: "priorities",
          selectedValues: [],
        },
      ];
    },
    // using 3
    selectedItemKey: {
      get() {
        return [];
      },
      set(val) {
        console.log(val);
      },
    },
    // using
    issueListFilterItems: {
      get() {
        return this.$store.getters["Ticket/issueGetters"](
          "issueListFilterItems"
        );
      },
      set(val) {
        this.$store.dispatch("Ticket/customFilter", val);
      },
    },
    assignees: function () {
      return this.$store.getters["Setup/setupGetters"]("assignees").map(
        (el) => {
          return {
            code: el.code,
            description: el.name,
          };
        }
      );
    },
    jiraUsers: function () {
      return this.$store.getters["Setup/setupGetters"]("reporters").filter((user) => !user.webUser).map(
        (el) => {
          return {
            code: el.code,
            description: el.name,
          };
        }
      );
    },
    webUsers: function () {
      return this.$store.getters["Setup/setupGetters"]("reporters").filter((user) => user.webUser).map(
        (el) => {
          return {
            code: el.code,
            description: el.name,
          };
        }
      );
    },
    clients: function () {
      return this.$store.getters["Setup/setupGetters"]("clients").map((el) => {
        return {
          code: el.id,
          description: el.first_name + " " + el.last_name,
        };
      });
    },
    // using 2
    filterItems: function () {
      return {
        projects: this.selectedField
          ? this.$store.getters["Setup/setupGetters"]("projects").filter(
              (el) => {
                return this.selectedField.label === "projects"
                  ? el.description
                      .toLowerCase()
                      .includes(this.search.toLowerCase())
                  : el;
              }
            )
          : this.$store.getters["Setup/setupGetters"]("projects"),

        assignees: this.assignees
          ? this.selectedField
            ? this.assignees.filter((el) => {
                return this.selectedField.label === "assignees"
                  ? el.code.toLowerCase().includes(this.search.toLowerCase())
                  : el;
              })
            : this.assignees
          : [],

        reporters: this.jiraUsers
          ? this.selectedField
            ? this.jiraUsers.filter((el) => {
                return this.selectedField.label === "reporters"
                  ? el.description
                      .toLowerCase()
                      .includes(this.search.toLowerCase())
                  : el;
              })
            : this.jiraUsers
          : [],

          "requested by": this.webUsers
          ? this.selectedField
            ? this.webUsers.filter((el) => {
                return this.selectedField.label === "requested by"
                  ? el.description
                      .toLowerCase()
                      .includes(this.search.toLowerCase())
                  : el;
              })
            : this.webUsers
          : [],

        status: this.selectedField
          ? this.$store.getters["Setup/setupGetters"]("status").filter((el) => {
              return this.selectedField.label === "status"
                ? el.description
                    .toLowerCase()
                    .includes(this.search.toLowerCase())
                : el;
            })
          : this.$store.getters["Setup/setupGetters"]("status"),

        priorities: this.selectedField
          ? this.$store.getters["Setup/setupGetters"]("priorities").filter(
              (el) => {
                return this.selectedField.label === "priorities"
                  ? el.description
                      .toLowerCase()
                      .includes(this.search.toLowerCase())
                  : el;
              }
            )
          : this.$store.getters["Setup/setupGetters"]("priorities"),
      };
    },
  },

  methods: {
    filterSelected: function () {
      this.$store.dispatch("Ticket/customFilter", [...this.filteredItems]);
    },
  },
};
</script>
<style lang="sass">
@import '~@/packages/tickets/modules/issues/styles/issues.sass'
</style>
