import { call } from "@/service";
import userManagementConstants from "@/packages/user-management/userManagementConstants";

export default {
  namespaced: true,
  state: {
    users: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    userGetters: (state) => (val) => state[val],
  },
  actions: {
    /**
     * System permissions*
     */
    users: ({ commit }) => {
      call("get", userManagementConstants.USERS)
        .then((res) => {
          commit("MUTATE", {
            state: "users",
            data: res.data.data.data,
          });
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    //save
    saveUser: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", userManagementConstants.USERS, data)
        .then((response) => {
          Event.$emit("ApiSuccess", response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
          dispatch("users");
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    deleteUser: ({ dispatch }, data) => {
      Event.$emit("deleting User", true);
      call("post", userManagementConstants.deleteUser(data))
        .then((response) => {
          Event.$emit("deleting User", false);
          Event.$emit("ApiSuccess", response.data.message);
          dispatch("users");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          Event.$emit("deleting User", false);
        });
    },
  },
};
