import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',[_c(VTextField,{staticClass:"mt-3",attrs:{"outlined":"","filled":"","dense":"","clearable":""},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)]),_c(VCardActions,{staticClass:"mt-n4"},[_c(VSpacer),_c(VBtn,{staticClass:"pa2 mr-2 text-body-2",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.updateDescription()}}},[_vm._v(" Save ")]),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.setDialog = false}}},[_vm._v(" close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }