<template>
  <v-card flat class="auth login-screen">
    <v-card-text class="d-flex flex-column justify-center">
      <div class="login-container align-self-center">
        <div class="text-center">
          <Logo class="mt-n8" />
          <div class="sign-in-text">
            <h5>Sign in to your account</h5>
          </div>
        </div>
        <v-form ref="loginForm" v-model="isValid">
          <v-text-field
            ref="email"
            v-model="formData.email"
            @copy.prevent
            outlined
            label="Email Address"
            placeholder="Enter your Email Address"
            prepend-inner-icon="email"
            :rules="rules.email"
            autocomplete="false"
            @keyup.enter="login()"
          />

          <v-text-field
            ref="password"
            v-model="formData.password"
            dense
            outlined
            @copy.prevent
            label="Password"
            placeholder="Enter your password"
            prepend-inner-icon="vpn_key"
            :type="showPassword ? 'text' : 'password'"
            :rules="rules.password"
            autocomplete="false"
            @keyup.enter="login()"
          >
            <template v-slot:append>
              <v-btn @click="showPassword = !showPassword" icon>
                <v-icon>{{
                  showPassword ? "visibility" : "visibility_off"
                }}</v-icon>
              </v-btn>
            </template>
          </v-text-field>
          <div class="d-flex align-center justify-space-between flex-wrap">
            <v-checkbox
              label="Remember Me"
              hide-details
              class="me-3 mt-1 forgot-text"
              >Remember
            </v-checkbox>

            <!-- forgot link -->
            <router-link
              :to="{ name: 'forgotPassword' }"
              class="mt-1 text-decoration-none forgot-text"
              >Forgot Password?
            </router-link>
          </div>
        </v-form>
        <div class="mt-6" v-if="!submitting">
          <v-btn
            block
            color="primary text-capitalize"
            width="100%"
            @click="login"
          >
            <v-icon class="mx-2"> mdi-lock-open-outline </v-icon>
            <div class="login-text">Login</div>
          </v-btn>
        </div>
        <div class="mt-6" v-if="submitting">
          <v-btn color="success" class="text-capitalize" width="100%">
            <i class="fa fa-refresh fa-spin loading-button-spinner"></i>
            Signing in...Please wait
          </v-btn>
        </div>
      </div>
      <div
        class="align-self-center sign--center make--application"
        v-if="!submitting"
      >
        <v-btn
          outlined
          class="teal darken-1 text-capitalize mt-3 white--text"
          width="100%"
          @click="scrollXY"
        >
          &nbsp; Proceed To Applications
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import RouterMixin from "../../../mixin/RouterMixin";
import Logo from "../../../components/Logo";

export default {
  name: "Login",
  components: { Logo },
  mixins: [RouterMixin],
  data: function () {
    return {
      submitting: false,
      isValid: false,
      showPassword: false,
      message: [
        "Enter valid Email address",
        "Enter valid Password",
        "Click on <strong>LOGIN</strong> button",
      ],
      formData: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    Event.$on("loggedIn", (val) => {
      this.submitting = val;
    });
    let message = window.localStorage.getItem("sessionExpiry");
    if (message) {
      this.$toast.info(message, {
        timeout: 0,
        position: "bottom-center",
        className: "toast",
      });
    } else {
      return null;
    }
    window.addEventListener("keyup", (event) => {
      if (event.keycode === 13) {
        this.login();
      }
    });
  },
  computed: {
    rules() {
      return {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [(v) => !!v || "Password is required"],
      };
    },
  },

  methods: {
    login: function () {
      this.submitting = true;
      if (!this.isValid) {
        this.$refs.loginForm.validate();
        this.submitting = false;
      } else {
        this.$store.dispatch("Auth/login", { ...this.formData });
      }
    },
    scrollXY: function () {
      this.$vuetify.goTo(9999, {
        duration: 800,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
  },
};
</script>

<style scoped>
@import url("../styles.css");
.login-screen {
  margin-top: 100px;
}
.toast {
  background: red;
}
@media (max-width: 480px) {
  .login-screen {
    margin-top: 0;
  }
}
</style>
