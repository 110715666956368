<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-container fluid v-can="`'${PERMISSIONS.CREATE_USERS}'`">
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title elevation="1">
          <h3 v-if="!routeParams">Add New User</h3>

          <v-spacer />
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-form class="mt-3" ref="userForm" v-model="isValid" :rules="rules">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Email Address"
                  v-model="formData.email"
                  outlined
                  type="email"
                  dense
                  required
                  :rules="[rules.email.required, rules.email.value]"
                />
              </v-col>

              <v-col cols="12" class="mt-n4">
                <v-select
                  label="User Type"
                  v-model="formData.user_type"
                  outlined
                  dense
                  required
                  :items="roles"
                  :item-text="(item) => item.name"
                  :item-value="(item) => item.value"
                  :rules="rules.user_type"
                />
              </v-col>
              <v-col cols="12" class="mt-n4">
                <v-autocomplete
                  v-model="formData.company"
                  :items="projects ? projects : []"
                  item-text="description"
                  item-value="code"
                  label="Project"
                  :rules="rules.required"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer />
              <v-btn
                class="mx-3"
                v-on:click="setDialog = false"
                color="error"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                v-can="`'${PERMISSIONS.CREATE_USERS}'`"
                color="primary"
                @click="saveNewUser"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Permissions from "@/shared/Permissions";
import helper from "../../dashboard/utils/helpers/helper";
export default {
  name: "UserManagementCard",
  props: {
    dialog: Boolean,
  },
  data: () => ({
    emailRegex:
      "/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$/;",
    isValid: false,
    PERMISSIONS: Permissions,
    imageError: "",
    url: process.env.VUE_APP_TEMP_URL,
    formData: {
      email: "",
      company: "",
      user_type: "",
    },
    rules: {
      first_name: [(v) => !!v || "First name is required"],
      last_name: [(v) => !!v || "Last name is required"],
      username: [(v) => !!v || "username is required"],
      email: {
        required: (value) => !!value || "Email is Required.",
        value: (value) =>
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            value
          ) || "E-mail must be valid",
      },
      company_email: {
        required: (value) => !!value || "Company Email is Required.",
        value: (value) =>
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            value
          ) || "E-mail must be valid",
      },
      company: [(v) => !!v || "Company is required"],
      phone: [(v) => !!v || "Phone is required"],
      avatar: [(v) => !!v || "Avatar is required"],
      address: [(v) => !!v || "Address is required"],
      user_type: [(v) => !!v || "Role is required"],
    },
    roles: [
      {
        name: "Agent Admin",
        value: "AGENT_ADMIN",
      },
      {
        name: "Agent",
        value: "AGENT",
      },
      {
        name: "Client",
        value: "CLIENT",
      },
      {
        name: "Client Admin",
        value: "CLIENT_ADMIN",
      },
    ],
  }),
  mounted() {
    this.$store.dispatch("Setup/getProjects");
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Setup/getProjects");
      v.$store.dispatch("UserManagement/users");
    });
  },
  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    projects: function () {
      return this.$store.getters["Setup/setupGetters"]("projects");
    },
    routeParams() {
      return this.$route.params.code;
    },
    user() {
      return this.$store.getters["UserManagement/userGetters"]("users").filter(
        (el) => el.id === this.routeParams
      );
    },
  },
  methods: {
    //save user
    saveNewUser() {
      if (!this.isValid) {
        this.$refs.userForm.validate();
      } else {
        this.$store.dispatch("UserManagement/saveUser", { ...this.formData });
        this.$refs.userForm.reset();
        this.setDialog = false;
      }
    },
    //handle file input
    async handleFileInput(fileInput) {
      let attachment = await Promise.all([helper.toBase64(fileInput)]);
      this.formData.avatar_url = attachment[0];
    },
  },
};
</script>

<style scoped></style>
