import DashboardLayout from "@/packages/dashboard/modules/dashboard/views/dashboardLayout";
import Auth from "@/packages/dashboard/modules/auth/middleware/Auth";
import Mail from "./views/Mail";
import MailList from "./components/MailList";
import MailCard from "./components/MailCard.vue";

export default [
  {
    path: "/mail",
    component: DashboardLayout,
    children: [
      {
        path: "",
        redirect: "/mail/clients",
      },
      {
        path: "clients",
        name: "MailClients",
        component: Mail,
        children: [
          {
            path: "",
            redirect: "/mail/clients/list",
          },
          {
            path: "list",
            name: "mailList",
            components: {
              view: MailList,
            },
            meta: { middleware: [Auth] },
          },
          {
            path: "card/:id?",
            name: "MailCard",
            components: {
              view: MailList,
              action: MailCard,
            },
            meta: { middleware: [Auth] },
          },
        ],
      },
    ],
  },
];
