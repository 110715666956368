import { render, staticRenderFns } from "./appAlert.vue?vue&type=template&id=88ed9ec0&scoped=true&"
import script from "./appAlert.vue?vue&type=script&lang=js&"
export * from "./appAlert.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88ed9ec0",
  null
  
)

export default component.exports