import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"d-flex flex-row",attrs:{"flat":""}},_vm._l((_vm.validStatus),function(status,i){return _c(VCard,{key:i,staticClass:"mr-2",attrs:{"color":"drawerBackground","flat":"","width":"300","loading":_vm.kanbanLoading}},[_c(VCardTitle,[_c('span',{staticClass:"board-header"}),_vm._v(_vm._s(status)+" "+_vm._s(_vm.issueCount(status))+" ")]),_c('draggable',{staticClass:"list-group",attrs:{"handle":".handle","group":{ name: status, put: true },"list":_vm.orderedIssues[status],"move":_vm.onMove},on:{"start":function($event){_vm.isDragging = true},"end":_vm.dragged}},[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.orderedIssues[status]),function(element,i){return _c(VCard,{key:i,ref:element.issueCode,refInFor:true,class:("ma-2 list-group-item " + (element.issueCode ? 'handle' : '')),attrs:{"elevation":!element.issueCode ? 0 : 1,"color":!element.issueCode ? 'drawerBackground' : ''},on:{"click":function($event){return _vm.selectIssue(element)}}},[(element.issueCode)?_c(VCardTitle,{staticClass:"text-body-2"},[_c('span',{domProps:{"innerHTML":_vm._s(element.description)}})]):_c(VCardTitle,{staticClass:"text-body-2 d-flex flex-column"},[_c('span',{staticClass:"align-self-center"},[_vm._v(_vm._s(element.description))])]),_c(VToolbar,{attrs:{"flat":"","dense":"","color":!element.issueCode ? 'drawerBackground' : ''}},[(element.issueType)?_c(VChip,{class:((_vm.issueTypeColor(
                element.issueType.toLowerCase()
              )) + "--text text--darken-4 text-capitalize mr-2"),attrs:{"label":"","small":"","color":((_vm.issueTypeColor(
                element.issueType.toLowerCase()
              )) + " lighten-5")}},[_vm._v(_vm._s(element.issueType))]):_vm._e(),(element.issueCode)?_c('span',{staticClass:"text-body-2 text-wrap"},[_vm._v(_vm._s(element.issueCode))]):_vm._e(),_c(VSpacer),(element.issueCode)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"mr-2 white--text",attrs:{"size":24,"color":element.assignee !== null
                      ? _vm.userColor(element.assignee)
                      : 'grey'}},'v-avatar',attrs,false),on),[(element.assignee === null)?_c('span',[_c(VIcon,[_vm._v("fa-solid fa-user")])],1):_c('span',[_vm._v(_vm._s(_vm.convertArrtoObj(element.assignee)))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(element.assignee ? element.assignee.username : "Unassigned"))])]):_vm._e()],1)],1)}),1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }