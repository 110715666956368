<template>
  <v-container>
    <v-card class="pt-5 pl-5" flat>
      <LineChart :data="usageStats" />
      <v-divider></v-divider>
      <DailyUsage :data="usageStats" />
      <v-spacer></v-spacer>
      <UserUsageList :data="usageStats" />
    </v-card>
  </v-container>
</template>

<script>
import LineChart from "../components/LineChart.vue";
import DailyUsage from "../components/DailyUsage.vue";
import UserUsageList from "../components/UserUsageList.vue";
export default {
  components: {
    LineChart,
    DailyUsage,
    UserUsageList,
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Usage/fetchStats");
      //   v.$store.dispatch("Usage/fetchActivities");
    });
  },
  computed: {
    usageStats() {
      return this.$store.getters["Usage/usageGetters"]("usageStats");
    },
    // activities() {
    //   return this.$store.getters["Usage/usageGetters"]("activities");
    // },
  },
};
</script>
