const SetupMixin = {
  methods: {
    getSetups: function (setups = []) {
      setups.forEach((setup) => {
        this.$store.dispatch(`Setup/${setup}`);
      });
    },
  },

  computed: {
    issueTypes() {
      return this.$store.getters["Setup/issueTypes"];
    },
    projects() {
      return this.$store.getters["Setup/projects"];
    },
    projectIssues() {
      return this.$store.getters["Setup/projectIssues"];
    },
  },
};

export default SetupMixin;
