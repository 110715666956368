<template>
  <v-dialog v-model="mailDialog" persistent width="70%">
    <v-card class="mx-auto">
      <v-card-title class="mail-title">
        <span v-if="$route.query.reply">
          <v-icon class="mr-2">reply</v-icon>
          <span>Reply Email</span>
        </span>
        <span v-else-if="$route.query.forward">
          <v-icon class="mr-2">forward</v-icon>
          Forward Email
        </span>
        <span v-else>Compose Email</span>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form ref="mailForm" v-model="isValid">
          <v-text-field
            v-model="formData.subject"
            label="Subject"
            placeholder="Enter email subject"
            outlined
            dense
            persistent-placeholder
            class="mt-3"
            :rules="[rules.required]"
          />
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="clients"
                placeholder="Select To Client"
                persistent-placeholder
                :rules="[rules.required]"
                label="To"
                clearable
                outlined
                dense
                item-text="email"
                item-value="email"
                v-model="formData.to"
                class="mr-1"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="primary"> contact_mail </v-icon>
                </template>
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item ripple v-on="on" v-bind="attrs">
                    <v-avatar
                      size="30"
                      class="indigo lighten-4 indigo--text text--darken-4 mr-3"
                    >
                      {{
                        `${item.first_name[0] || ""}${item.last_name[0] || ""}`
                      }}
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>
                          {{ item.first_name + " " + item.last_name }}
                          <v-chip
                            left
                            x-small
                            color="indigo lighten-4"
                            class="indigo--text text--darken-4 mx-2"
                          >
                            {{ item.user_code }}
                          </v-chip>
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.email
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip small label color="indigo lighten-4">
                        {{ item.company }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="clients"
                placeholder="Select CC Client (Optional)"
                persistent-placeholder
                label="CC (Optional)"
                clearable
                outlined
                multiple
                chips
                dense
                item-text="email"
                item-value="email"
                v-model="formData.cc"
                class="ml-1"
              >
                <template v-slot:prepend-inner>
                  <v-icon color="primary"> contact_mail </v-icon>
                </template>
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item ripple v-on="on" v-bind="attrs">
                    <v-avatar
                      size="30"
                      class="indigo lighten-4 indigo--text text--darken-4 mr-3"
                    >
                      {{
                        `${item.first_name[0] || ""}${item.last_name[0] || ""}`
                      }}
                    </v-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span>
                          {{ item.first_name + " " + item.last_name }}
                          <v-chip
                            left
                            x-small
                            color="indigo lighten-4"
                            class="indigo--text text--darken-4 mx-2"
                          >
                            {{ item.user_code }}
                          </v-chip>
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.email
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip small label color="indigo lighten-4">
                        {{ item.company }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <div class="d-flex flex-column mt-n3">
            <span>Body</span>
            <Editor
              class="editor"
              v-model="formData.body"
              :editorToolbar="customToolbar"
            />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions class="mail-actions">
        <v-btn
          class="deep-orange lighten-4 deep-orange--text text--darken-4"
          @click="$router.back()"
          elevation="0"
        >
          <v-icon class="mx-2">arrow_back</v-icon>
          Go Back
        </v-btn>
        <v-spacer />
        <v-btn
          class="primary white--text text-body-1 text-capitalize"
          @click="sendMail"
        >
          Send Email
          <v-icon class="mx-2">send</v-icon>
        </v-btn>
      </v-card-actions>
      <dialog-spinner-vue />
    </v-card>
  </v-dialog>
</template>
<script>
import DialogSpinnerVue from "@/packages/dashboard/plugins/loader/views/DialogSpinner.vue";
import { VueEditor as Editor } from "vue2-editor";
import mailmixin from "../mailmixin";
export default {
  name: "MailCard",
  mixins: [mailmixin],
  components: {
    Editor,
    DialogSpinnerVue,
  },
  data() {
    return {
      mailDialog: true,
      isValid: false,
      formData: {
        to: "",
        cc: [],
        subject: "",
        body: "",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Mail/getUsers");
      if (v.isEdit) {
        v.$store.dispatch("Mail/getMessage", to.params.id);
      }
    });
  },
  computed: {
    clients: function () {
      return this.$store.getters["Mail/mailGetters"]("users");
    },
    message: function () {
      return this.$store.getters["Mail/mailGetters"]("mailmessage");
    },
    customToolbar() {
      return [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ];
    },
    rules() {
      return {
        required: (v) => !!v || "This field is required",
      };
    },
    isEdit() {
      return this.$route.params.id;
    },
  },
  methods: {
    sendMail() {
      if (!this.isValid) {
        this.$refs.mailForm.validate();
      } else {
        const data = {
          ...this.formData,
        };
        this.$store.dispatch("Mail/sendMail", data);
      }
    },
  },
  watch: {
    message: {
      handler: function (val) {
        if (val) {
          this.formData = {
            ...this.formData,
            ...val,
            cc:
              val.cc !== null
                ? val.cc.includes(",")
                  ? val.cc.split(",")
                  : [val.cc]
                : [],
            subject: this.$route.query.reply ? "" : val.subject,
            body: this.$route.query.reply ? "" : val.body,
          };
          if (!val.read) {
            this.$store.dispatch("Mail/readMessage", val);
          }
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.mail-title {
  position: sticky;
  top: 0;
  z-index: 1;
}

.mail-actions {
  position: sticky;
  bottom: 0;
  z-index: 1;
}
</style>
