import DashboardLayout from "@/packages/dashboard/modules/dashboard/views/dashboardLayout";
import Auth from "@/packages/dashboard/modules/auth/middleware/Auth";
import UsageStatsView from "./views/UsageStatsView.vue";
export default [
  {
    path: "/usage",
    component: DashboardLayout,
    children: [
      {
        path: "",
        redirect: "/usage/stats",
      },
      {
        path: "stats",
        name: "UsageStats",
        component: UsageStatsView,
        meta: { middleware: [Auth] },
      },
    ],
  },
];
