<template>
  <!-- eslint-disable vue/valid-v-slot -->
  <v-container fluid v-can="`'${PERMISSIONS.VIEW_ROLES}'`">
    <v-row>
      <v-col cols="12">
        <v-card class="mt-3" flat>
          <v-card-text>
            <v-row class="mt-2 pa-2">
              <h3>System Roles</h3>
              <v-spacer />
              <v-btn
                v-can="`'${PERMISSIONS.CREATE_ROLES}'`"
                @click="redirectToRoleCard()"
                color="primary"
                >New Role</v-btn
              >
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-data-table :headers="headers" :items="roles" :items-per-page="5">
              <template v-slot:item.id="{ index }">
                <span>{{ index + 1 }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu open-on-hover>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="text-capitalize links"
                      text
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      <i class="fa-solid fa-ellipsis-vertical"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-can="`'${PERMISSIONS.VIEW_ROLE}'`"
                      ><span
                        ><v-icon color="primary" size="">mdi-eye</v-icon>
                        view</span
                      ></v-list-item
                    >
                    <v-divider />
                    <v-list-item
                      @click="redirectToRoleCard(item.name)"
                      v-can="`'${PERMISSIONS.EDIT_ROLES}'`"
                    >
                      <span
                        ><v-icon color="primary" size="">mdi-pencil-box</v-icon>
                        edit</span
                      ></v-list-item
                    >
                    <v-divider />
                    <v-list-item v-can="`'${PERMISSIONS.DELETE_ROLES}'`"
                      ><span
                        ><v-icon color="secondary" size="">mdi-delete</v-icon>
                        delete</span
                      ></v-list-item
                    >
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Permissions from "@/shared/Permissions";
import helper from "@/packages/dashboard/utils/helpers/helper";

export default {
  name: "AccessRoles",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("AccessControl/roles");
    });
  },
  data: function () {
    return {
      PERMISSIONS: Permissions,
      headers: [
        {
          text: "#S/N",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Display Name",
          align: "start",
          sortable: false,
          value: "display_name",
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    roles() {
      return this.$store.getters["AccessControl/accessGetters"]("roles");
    },
  },
  methods: {
    redirectToRoleCard(name) {
      if (name) {
        this.$router.push({
          name: "AccessRoleCard",
          params: { code: helper.encrypt(name) },
        });
      } else {
        this.$router.push({
          name: "AccessRoleCard",
        });
      }
    },
  },
};
</script>

<style scoped></style>
