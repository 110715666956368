import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,[_vm._v(" Client Email Inbox/Outbox "),_c(VSpacer),_c(VBtn,{staticClass:"mx-2",attrs:{"icon":""},on:{"click":_vm.refreshMessages}},[_c(VIcon,[_vm._v("refresh")])],1),_c(VBtn,{staticClass:"primary text-capitalize mx-2",on:{"click":_vm.compose}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v("email")]),_vm._v(" Compose Mail ")],1)],1),_c(VCardText,{staticClass:"mt-n5"},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.messages,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"indigo lighten-4 indigo--text text--darken-4",attrs:{"label":""}},[_vm._v(" "+_vm._s(item.from)+" ")])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"primary lighten-4 primary--text text--darken-4",attrs:{"label":""}},[_vm._v(" "+_vm._s(item.to)+" ")])]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('a',{class:item.is_read
                ? 'text-truncate'
                : 'primary--text font-weight-bold text-body-1 text-truncate',staticStyle:{"max-width":"200px"},on:{"click":function($event){return _vm.view(item)}}},[_c('div',{domProps:{"innerHTML":_vm._s(item.subject)}})])]}},{key:"item.body",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-truncate text-wrap",staticStyle:{"max-width":"200px"}},[_c('div',{domProps:{"innerHTML":_vm._s(item.body)}})])]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.view(item)}}},on),[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v("View Email")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mr-2",attrs:{"icon":"","disabled":!item.isReply},on:{"click":function($event){return _vm.reply(item)}}},on),[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("reply")])],1)]}}],null,true)},[_c('span',[_vm._v("Reply")])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.forward(item)}}},on),[_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("redo")])],1)]}}],null,true)},[_c('span',[_vm._v("Forward")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteMessage(item)}}},on),[_c(VIcon,{attrs:{"color":"error"}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }