import userManagementRoutes from "@/packages/user-management/userManagementRoutes";
import UserManagementStore from "@/packages/user-management/userManagementStore";
import UserManagementLinks from "@/packages/user-management/userManagementLinks";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoute(...userManagementRoutes);
    }
    if (options.store) {
      options.store.registerModule("UserManagement", UserManagementStore);
      options.store.dispatch("Dashboard/setLinks", UserManagementLinks);
    }
  },
};
