import call from "../../../../service/http";
import issueConstant from "./issueConstants";
export default {
  namespaced: true,
  state: {
    issuesModal: false,
    tickets: [],
    allComments: [],
    ticketLog: [],
    validStatuses: [],
    filteredtickets: [],
    selectedIssue: {},
    kanbanLoading: false,
    selfLoading: {
      status: false,
      dueDate: false,
      priorities: false,
      agent_id: false,
      comments: false,
    },
    view: "List",
    selectedItem: {},
    excelTemplate: "",
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.value;
    },
    CREATE_TICKET_SOCKET: (state, payload) => {
      state.tickets.unshift(payload);
    },
    UPDATE_TICKET_SOCKET: (state, payload) => {
      const index = state.tickets.findIndex(
        (ticket) => ticket.id === payload.id
      );
      if (index !== -1) {
        state.tickets.splice(index, 1, payload);
      }
    },
    UPDATE_STATUS_KANBAN: (state, payload) => {
      const index = state.tickets.findIndex(
        (ticket) => ticket.issueCode === payload.issueCode
      );
      if (index !== -1) {
        state.tickets[index].status = payload.status;
      }
    },
  },
  getters: {
    issueGetters: (state) => (val) => state[val],
  },
  actions: {
    updateView: ({ commit }, payload) => {
      commit("MUTATE", { state: "view", value: payload });
    },
    updateSelectedItem: ({ commit }, payload) => {
      commit("MUTATE", { state: "selectedItem", value: payload });
    },
    addIssue({ commit }, payload) {
      commit("MUTATE", { state: "issuesModal", value: payload });
    },
    getIssues({ commit }) {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", issueConstant.tickets)
        .then((res) => {
          commit("MUTATE", { state: "tickets", value: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getIssue({ commit }, payload) {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", issueConstant.ISSUE(payload))
        .then((res) => {
          commit("MUTATE", { state: "selectedIssue", value: res.data.data[0] });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    saveIssue: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.saveIssue, data)
        .then(() => {
          Event.$emit(
            "ApiSuccess",
            "You have successfully created a new Issue"
          );
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssues");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },

    patchIssue: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.patchIssue(data.id), data)
        .then(() => {
          Event.$emit("ApiSuccess", "You have successfully Edited  Issue");
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssues");
          Event.$emit("back");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },

    assignIssue: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.assignIssue, data)
        .then(() => {
          Event.$emit("ApiSuccess", "You have successfully Assigned Issue");
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssues");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },

    selfUpdate: ({ commit, dispatch }, data) => {
      commit("MUTATE", { state: "loading", value: false });
      call("post", issueConstant.patchIssue(data.issueCode), data)
        .then(() => {
          Event.$emit("ApiSuccess", "You have successfully Updated Issue");
          dispatch("selfGetIssues");
          commit("MUTATE", { state: "loading", value: false });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("MUTATE", { state: "loading", value: false });
        });
    },

    selfGetIssues: ({ commit, state }) => {
      const selfLoading = { ...state.selfLoading };
      Object.keys(selfLoading).forEach((key) => {
        selfLoading[key] = false;
      });
      call("get", issueConstant.tickets)
        .then((res) => {
          commit("MUTATE", { state: "selfLoading", value: selfLoading });
          commit("MUTATE", { state: "tickets", value: res.data.data });
        })
        .catch((error) => {
          commit("MUTATE", { state: "selfLoading", value: selfLoading });
          Event.$emit("ApiError", error.response.data.message);
        });
    },

    getComments({ commit }) {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("get", issueConstant.allComments)
        .then((res) => {
          commit("MUTATE", { state: "allComments", value: res.data.data });
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    getValidStatuses({ commit }) {
      call("get", issueConstant.VALID_STATUSES).then((res) => {
        commit("MUTATE", { state: "validStatuses", value: res.data.data });
      });
    },
    addChildIssue({ commit, dispatch }, payload) {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.ADD_CHILD_ISSUE, payload.data)
        .then(() => {
          Event.$emit(
            "ApiSuccess",
            "You have successfully added a child issue"
          );
          dispatch("getIssue", payload.parentIssue);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    updateStatusKanban({ commit, dispatch }, payload) {
      commit("UPDATE_STATUS_KANBAN", payload);
      call("post", issueConstant.UPDATE_STATUS, payload)
        .then(() => {})
        .catch((error) => {
          dispatch("getIssues");
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    UpdateTicketStatus: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.UPDATE_STATUS, data)
        .then(() => {
          Event.$emit("ApiSuccess", "You have successfully Updated Issue");
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", data.issueCode);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    updateChildIssue: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.UPDATE_CHILD_ISSUE, data)
        .then(() => {
          Event.$emit(
            "ApiSuccess",
            "You have successfully Updated Child Issue"
          );
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", data.parentIssue);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    deleteChildIssue: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.DELETE_CHILD_ISSUE, data)
        .then(() => {
          Event.$emit(
            "ApiSuccess",
            "You have successfully Deleted Child Issue"
          );
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", data.parentIssue);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    deleteParentIssue: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.DELETE_ISSUE, data)
        .then(() => {
          Event.$emit("ApiSuccess", "You have successfully Deleted Issue");
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssues");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    updateComment: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.UPDATE_COMMENT, data)
        .then(() => {
          Event.$emit("ApiSuccess", "You have successfully Updated Comment");
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", data.issue);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    deleteComment: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.DELETE_COMMENT, data)
        .then(() => {
          Event.$emit("ApiSuccess", "You have successfully Deleted Comment");
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", data.issue);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    saveComment: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.SAVE_COMMENT, data)
        .then(() => {
          Event.$emit("ApiSuccess", "You have successfully Saved Comment");
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", data.issue);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    deleteAttachment: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.DELETE_ATTACHMENT, data)
        .then(() => {
          Event.$emit("ApiSuccess", "You have successfully Deleted Attachment");
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", data.no);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    updateAttachment: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.UPDATE_ATTACHMENT, data.sentData)
        .then(() => {
          Event.$emit(
            "ApiSuccess",
            "You have successfully UpLoaded Attachment"
          );
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
          dispatch("getIssue", data.issueCode);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },
    deleteParentIssueList: ({ commit, dispatch }, data) => {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.DELETE_ISSUE, data.delData)
        .then(() => {
          dispatch("CreateChildfromList", data.childData);
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },

    CreateChildfromList({ commit, dispatch }, data) {
      commit("Dashboard/SET_DIALOG_LOADING", true, { root: true });
      call("post", issueConstant.ADD_CHILD_ISSUE, data)
        .then(() => {
          Event.$emit(
            "ApiSuccess",
            "You have successfully added a child issue"
          );
          dispatch("getIssue", data.parentIssue);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_DIALOG_LOADING", false, { root: true });
        });
    },

    customFilter: ({ commit }, data) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("post", issueConstant.FILTER_ISSUES, data)
        .then((res) => {
          commit("MUTATE", { state: "tickets", value: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
  },
};
