import landing from "./views/landing";
import LandingwithToken from "./views/LandingwithToken";

export default [
  {
    path: "/landing",
    component: landing,
    name: "Landing",
  },
  {
    path: "/auth/authenticate/:token",
    component: LandingwithToken,
    name: "LandingwithToken",
  },
];
