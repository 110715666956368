<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card>
      <v-card-title class="text-h5 lighten-2">
        Preview
        <v-spacer>
          <!--Download button-->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="teal darken-4"
                label
                small
                dense
                @click="downloadLink(`${url}${imagetoPreview.previewDoc}`)"
                v-bind="attrs"
                v-on="on"
                class="ml-3 white--text"
              >
                <v-icon>mdi-download-circle</v-icon>Download
              </v-btn>
            </template>
            <span>Download File</span>
          </v-tooltip>
          <!--download button end-->
        </v-spacer>
        <v-card-actions>
          <v-icon color="error" v-on:click="setDialog = false" size="30"
            >fa fa-times</v-icon
          >
        </v-card-actions>
      </v-card-title>

      <div v-if="imagetoPreview.Extension === 'pdf'">
        <pdf
          v-for="i in getPages(`${url}${imagetoPreview.previewDoc}`)"
          :key="i"
          :src="`${url}${imagetoPreview.previewDoc}`"
          :page="i"
        ></pdf>
      </div>
      <div v-if="imagetoPreview.Extension !== 'pdf'">
        <v-img alt="" :src="`${url}${imagetoPreview.previewDoc}`"></v-img>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import pdf from "vue-pdf";
import FileMixin from "@/packages/dashboard/mixin/FileMixin";
export default {
  components: {
    pdf,
  },
  mixins: [FileMixin],
  name: "ImagePdfPreview",
  props: {
    imagetoPreview: Object,
    dialog: Boolean,
  },
  data: function () {
    return {
      url: process.env.VUE_APP_TEMP_URL,
      src: "",
      numPages: undefined,
    };
  },
  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
  },

  methods: {
    getPages: function (document) {
      if (document.length !== 0) {
        document = pdf.createLoadingTask(document);
        document.promise.then((pdf) => {
          this.numPages = pdf._pdfInfo.numPages;
        });
        return this.numPages;
      }
    },
  },
};
</script>

<style scoped></style>
