import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{directives:[{name:"can",rawName:"v-can",value:(("'" + (_vm.PERMISSIONS.VIEW_ROLES) + "'")),expression:"`'${PERMISSIONS.VIEW_ROLES}'`"}],attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"mt-3",attrs:{"flat":""}},[_c(VCardText,[_c(VRow,{staticClass:"mt-2 pa-2"},[_c('h3',[_vm._v("System Roles")]),_c(VSpacer),_c(VBtn,{directives:[{name:"can",rawName:"v-can",value:(("'" + (_vm.PERMISSIONS.CREATE_ROLES) + "'")),expression:"`'${PERMISSIONS.CREATE_ROLES}'`"}],attrs:{"color":"primary"},on:{"click":function($event){return _vm.redirectToRoleCard()}}},[_vm._v("New Role")])],1)],1),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.roles,"items-per-page":5},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"text-capitalize links",attrs:{"text":"","small":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fa-solid fa-ellipsis-vertical"})])]}}],null,true)},[_c(VList,[_c(VListItem,{directives:[{name:"can",rawName:"v-can",value:(("'" + (_vm.PERMISSIONS.VIEW_ROLE) + "'")),expression:"`'${PERMISSIONS.VIEW_ROLE}'`"}]},[_c('span',[_c(VIcon,{attrs:{"color":"primary","size":""}},[_vm._v("mdi-eye")]),_vm._v(" view")],1)]),_c(VDivider),_c(VListItem,{directives:[{name:"can",rawName:"v-can",value:(("'" + (_vm.PERMISSIONS.EDIT_ROLES) + "'")),expression:"`'${PERMISSIONS.EDIT_ROLES}'`"}],on:{"click":function($event){return _vm.redirectToRoleCard(item.name)}}},[_c('span',[_c(VIcon,{attrs:{"color":"primary","size":""}},[_vm._v("mdi-pencil-box")]),_vm._v(" edit")],1)]),_c(VDivider),_c(VListItem,{directives:[{name:"can",rawName:"v-can",value:(("'" + (_vm.PERMISSIONS.DELETE_ROLES) + "'")),expression:"`'${PERMISSIONS.DELETE_ROLES}'`"}]},[_c('span',[_c(VIcon,{attrs:{"color":"secondary","size":""}},[_vm._v("mdi-delete")]),_vm._v(" delete")],1)])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }