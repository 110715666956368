export default {
  issueTypes: "setups/issueTypes",
  projects: "setups/projectsApi",
  projectIssues: "tickets",
  modules: "setups/projectProductModules",
  reporters: "users/reporters",
  assignees: "users/assignees",
  clients: "users/clients",
  status: "setups/issueStatus",
  priorities: "setups/issuePriorities",
};
