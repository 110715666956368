export default {
  //Sidebar permissions:
  VIEW_MAIN_DASHBOARD: "view main dashboard",
  //access control
  VIEW_ACCESS_CONTROL_MENU: "view access control menu",
  VIEW_ACCESS_CONTROL_ROLES_MENU: "view access control roles menu",
  VIEW_ACCESS_CONTROL_PERMISSIONS_MENU: "view access control permissions menu",
  //user management
  VIEW_USER_MANAGEMENT_MENU: "view user management menu",
  VIEW_USER_MANAGEMENT_USERS_MENU: "view user management users menu",
  //tickets menu
  VIEW_TICKETS_MENU: "view tickets menu",
  VIEW_TICKETS_ISSUES_MENU: "view tickets issues menu",
  //projects
  VIEW_PROJECTS_MENU: "view projects menu",
  //add more sidebar menus below this comment:::::::::::::::::::::::::::
  /**
   * Pages
   */
  //projects
  VIEW_PROJECTS: "view projects",
  VIEW_PROJECT: "view project", //project details
  VIEW_PROJECT_TIMELINE: "view project timeline", //project details
  VIEW_PROJECT_STATUS_TRACKER: "view project status tracker", //project status
  VIEW_PROJECT_TABS: "view project tabs", //project status
  CREATE_PROJECTS: "create projects",
  EDIT_PROJECTS: "edit projects",
  DELETE_PROJECTS: "delete projects",
  //Access Control
  VIEW_PERMISSIONS: "view permissions",
  CREATE_PERMISSIONS: "create permissions",
  VIEW_ROLES: "view roles",
  VIEW_ROLE: "view role",
  CREATE_ROLES: "create roles",
  DELETE_ROLES: "delete roles",
  EDIT_ROLES: "edit roles",
  //user management
  VIEW_USERS: "view users",
  VIEW_USER: "view user",
  CREATE_USERS: "create users",
  EDIT_USERS: "edit users",
  DELETE_USERS: "delete users",
  //Ticket Management
  VIEW_ISSUES: "view issues",
  VIEW_ISSUE: "view issue",
  CREATE_ISSUES: "create issues",
  EDIT_ISSUES: "edit issues",
  DELETE_ISSUES: "delete issues",
  //create
};
