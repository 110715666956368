export default {
  password: "auth/set-password",
  notification: "auth/notification",
  companies: "company/companies",
  getRegistrations: "student/programme-registration",
  intakePeriods: "setup/intakePeriod",
  balance: (id) => `student/getBalance?profileNo=${id}`,
  updateProfile: (id) => `users/update/${id}`,
  getAuthUser: "users/show",
  company: "company",
};
