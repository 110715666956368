import router from "@/router";
import { helper } from "../../packages/dashboard/utils";
//create directive
const grant = {
  bind: function (el, binding, vnode) {
    //where is the binding
    let className = vnode.elm.className;
    //get all permissions
    let permissions = helper.getLocalStorage("permissions");
    let currentPerms = [];
    for (let perm of permissions) {
      currentPerms.push(perm.name);
    }

    let rule = binding.value.replace(/'/g, "").replace(/"/g, "");
    //binding.expression.replace(/'/g, "").replace(/"/g, "")
    if (!currentPerms.includes(rule)) {
      // Permission has been denied, check if the condition is on the page or button
      if (
        className === "container" ||
        className === "container container--fluid"
      ) {
        // Since it's a page, instead of showing blank page, redirect to 403 page
        router.push({ name: "PermissionForbidden" });
      } else {
        // Since it's a button, remove it from the DOM.
        vnode.elm.style.display = "none";
      }
    }
  },
};
export default grant;
