<template>
  <v-app id="inspire" style="background-color: #f0f0f0">
    <AppBarTopVue />
    <DrawerBarVue />
    <div>
      <router-view />
    </div>
    <spinner />
    <confirmation />
    <Footer />
  </v-app>
</template>

<script>
import Footer from "../../../components/Footer";
import Spinner from "../../../plugins/loader/views/Spinner";
import { AuthService } from "../../auth";
/** components */
import AppBarTopVue from "../components/Bars/AppBarTop.vue";
import DrawerBarVue from "../components/Bars/DrawerBar.vue";
// import ToolBarTopVue from "../components/Bars/ToolBarTop.vue";

export default {
  name: "DashboardLayout",
  components: {
    Spinner,
    Footer,
    AppBarTopVue,
    DrawerBarVue,
    // ToolBarTopVue,
  },
  data: () => ({
    drawer: null,
    leftDrawer: false,
    mini: false,
    selectedItem: 0,
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Dashboard/getCompanyInfo");
    });
  },

  computed: {
    user() {
      return AuthService.user;
    },

    links() {
      return this.$store.getters["Dashboard/links"]
        .filter((item) => !item.disabled && item.title !== "Administration")
        .map((link) => {
          link.sublinks = link.sublinks || [];
          return link;
        });
    },
    adminLinks() {
      return this.$store.getters["Dashboard/links"]
        .filter((item) => !item.disabled && item.title === "Administration")
        .map((link) => {
          link.sublinks = link.sublinks || [];
          return link;
        });
    },

    actions() {
      return this.$store.getters["Dashboard/actions"];
    },

    menus() {
      return this.$store.getters["Dashboard/menus"];
    },
  },
  methods: {
    logout() {
      AuthService.logout();
    },
  },
};
</script>

<style>
@import url("../styles.css");
</style>
