import DashboardLayout from "@/packages/dashboard/modules/dashboard/views/dashboardLayout";
import UserManagement from "@/packages/user-management/view/UserManagement";
import UserManagementList from "@/packages/user-management/components/UserManagementList";
import UserManagementCard from "@/packages/user-management/components/UserManagementCard";

export default [
  {
    path: "/user-management",
    component: DashboardLayout,
    children: [
      {
        path: "system",
        name: "UserManagement",
        component: UserManagement,
        children: [
          {
            path: "users",
            component: UserManagementList,
            name: "UserManagementList",
          },
          {
            path: "users/add-new/:code?",
            component: UserManagementCard,
            name: "UserManagementCard",
          },
        ],
      },
    ],
  },
];
