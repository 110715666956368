const appName = process.env.VUE_APP_NAME;
const appUrl = process.env.VUE_APP_URL;
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
const githubToken = process.env.VUE_GITHUB_TOKEN;
const githubOrganization = process.env.VUE_GITHUB_ORGANIZATION_NAME;
const githubRepo = process.env.VUE_GITHUB_REPO;
const socketBaseUrl = process.env.VUE_APP_SOCKET_URL;

export {
  appName,
  appUrl,
  apiBaseUrl,
  githubToken,
  githubRepo,
  githubOrganization,
  socketBaseUrl,
};
