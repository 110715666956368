<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-col cols="12">
        <div>
          <v-text-field
            class="mt-3"
            v-model="formData.description"
            outlined
            filled
            dense
            clearable
          ></v-text-field>
        </div>
      </v-col>
      <v-card-actions class="mt-n4">
        <v-spacer></v-spacer>
        <v-btn
          class="pa2 mr-2 text-body-2"
          x-small
          color="primary"
          @click="updateDescription()"
        >
          Save
        </v-btn>
        <v-btn color="error" text v-on:click="setDialog = false"> close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import issueMixin from "../issueMixin";
export default {
  name: "EditChildIssue",
  mixins: [issueMixin],
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    commentData: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      formData: {
        description: "",
      },
    };
  },

  computed: {
    setDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close-dialog", val);
      },
    },
    // getSelectedAssignee: function () {
    //   const data = this.listItems[`${this.selectedItem}`].code;
    //   return data;
    // },
  },

  methods: {
    updateDescription() {
      const val = this.commentData;
      const data = {
        assignee: this.commentData.assignee,
        description: this.formData.description,
        dueDate: val.dueDate,
        lineNo: val.lineNo,
        parentIssue: val.parentIssue,
        projectCode: val.projectCode,
        status: val.status,
      };
      this.$store.dispatch("Ticket/updateChildIssue", data);
      setTimeout(() => (this.setDialog = false), 2000);
    },
  },
  watch: {
    commentData: {
      handler: function () {
        this.formData = { ...this.commentData };
      },
    },
  },
};
</script>
