import { upperCase } from "lodash";

export default {
  data() {
    return {
      isConnected: false,
      socketMessage: "",
    };
  },

  sockets: {
    connect() {
      console.log("socket connected");
      this.isConnected = true;
    },
    disconnect() {
      console.log("socket disconnected");
      this.isConnected = false;
    },
    "tickets-channel": function (data) {
      this.showDesktopNotification({
        mesage: upperCase(data.event.split(":")[0]),
        body: upperCase(data.event.split(":")[0]),
        icon: "https://cdn4.iconfinder.com/data/icons/technology-devices-1/500/speech-bubble-128.png",
        title: upperCase(data.event.split(":")[0]),
      });
      if (data.event === "addticket:event") {
        this.playAudio();
      }
      this.$store.dispatch("Socket/ticketSocket", data);
    },
  },

  methods: {
    pingServer() {
      this.$socket.emit("pingServer", "ping");
    },
    playAudio: function () {
      var audio = document.getElementById("audio");
      audio.play();
    },
  },
};
