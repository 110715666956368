import DashboardLayout from "@/packages/dashboard/modules/dashboard/views/dashboardLayout";
import AccessControl from "@/packages/access-control/view/AccessControl";
import AccessRoles from "@/packages/access-control/components/AccessRoles";
import AccessPermission from "@/packages/access-control/components/AccessPermission";
import AccessRoleCard from "@/packages/access-control/components/AccessRoleCard";

export default [
  {
    path: "/access-control",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "AccessControl",
        component: AccessControl,
        children: [
          {
            path: "roles",
            component: AccessRoles,
            name: "AccessRoles",
          },
          {
            path: "permissions",
            component: AccessPermission,
            name: "AccessPermissions",
          },
          {
            path: "role-card/:code?",
            component: AccessRoleCard,
            name: "AccessRoleCard",
          },
        ],
      },
    ],
  },
];
