<template>
  <v-card style="margin-top: -5px; width: 100vw" flat>
    <v-card-title class="pt-5">Usage Today</v-card-title>
    <v-divider></v-divider>
    <v-card 
      class="d-flex flex-row pt-5 pl-5" 
      flat
      v-if="usageToday"
    >
      <PieChart :data="data" :currentData="isUsageToday"/>
      <BarChart :data="data" />
    </v-card>
    <v-card v-else flat>
      <v-card-text>No usage data available for today!</v-card-text>
    </v-card>
  </v-card>
</template>
<script>
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";
export default {
  name: "DailyUsage",
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  components: {
    PieChart,
    BarChart,
  },
  data() {
    return {
      usageToday: true,
    };
  },
  methods: {
    isUsageToday(arr) {
      if (arr.length < 1) {
        this.usageToday = false;
      } else {
        this.usageToday = true;
      }
    },
  },
};
</script>
