import { call } from "@/service";
import accessControlConstants from "@/packages/access-control/accessControlConstants";
import helper from "@/packages/dashboard/utils/helpers/helper";

export default {
  namespaced: true,
  state: {
    permissions: [],
    roles: [],
    role: {},
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    accessGetters: (state) => (val) => state[val],
  },
  actions: {
    /**
     * System permissions*
     */
    roles: ({ commit }) => {
      call("get", accessControlConstants.roles)
        .then((res) => {
          commit("MUTATE", {
            state: "roles",
            data: res.data.data.data,
          });
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    role: ({ commit }, payload) => {
      call("get", accessControlConstants.role(payload))
        .then((res) => {
          commit("MUTATE", {
            state: "role",
            data: res.data.data,
          });
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
        });
    },
    //save
    saveRole: ({ dispatch }, data) => {
      Event.$emit("savingRole", true);
      call("post", accessControlConstants.roles, data)
        .then((response) => {
          Event.$emit("savingRole", false);
          Event.$emit("ApiSuccess", response.data.message);
          dispatch("roles");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          Event.$emit("savingRole", false);
        });
    },
    //edit
    editRole: ({ dispatch }, payload) => {
      Event.$emit("savingRole", true);
      call(
        "patch",
        accessControlConstants.role(helper.encrypt(payload.name)),
        payload
      )
        .then((response) => {
          Event.$emit("savingRole", false);
          Event.$emit("ApiSuccess", response.data.message);
          dispatch("roles");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          Event.$emit("savingRole", false);
        });
    },
    /**
     * System permissions*
     */
    permissions: ({ commit }) => {
      call("get", accessControlConstants.permissions)
        .then((res) => {
          commit("MUTATE", {
            state: "permissions",
            data: res.data.data.data,
          });
        })
        .catch((err) => {
          Event.$emit("ApiError", err.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    //save
    savePermission: ({ dispatch }, data) => {
      Event.$emit("savingPermission", true);
      call("post", accessControlConstants.permissions, data)
        .then((response) => {
          Event.$emit("savingPermission", false);
          Event.$emit("ApiSuccess", response.data.message);
          dispatch("permissions");
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          Event.$emit("savingPermission", false);
        });
    },
  },
};
