import mailRoutes from "./mailRoutes";
import mailStore from "./mailStore";
import mailLinks from "./mailLinks";

export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoutes(mailRoutes);
    }

    if (options.store) {
      options.store.registerModule("Mail", mailStore);
      options.store.dispatch("Dashboard/setLinks", mailLinks);
    }
  },
};
