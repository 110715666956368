<template>
  <router-view />
</template>

<script>
import userManagementActionLinks from "@/packages/user-management/userManagementActionLinks";

export default {
  name: "UserManagement",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Dashboard/setToolBarLinks", userManagementActionLinks);
    });
  },
};
</script>

<style scoped></style>
