import call from "../../service/http";
import SetupConstants from "./SetupConstants";

export default {
  namespaced: true,
  state: {
    issueTypes: [],
    projects: [],
    projectIssues: [],
    reporters: [],
    assignees: [],
    clients: [],
    status: [],
    priorities: [],
    modules: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    setupGetters: (state) => (val) => state[val],
  },
  actions: {
    getissueTypes: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", SetupConstants.issueTypes)
        .then((res) => {
          commit("MUTATE", { state: "issueTypes", data: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    getIssueStatus: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", SetupConstants.status)
        .then((res) => {
          commit("MUTATE", { state: "status", data: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    getIssuePriorities: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", SetupConstants.priorities)
        .then((res) => {
          commit("MUTATE", { state: "priorities", data: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    getProjects: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", SetupConstants.projects)
        .then((res) => {
          commit("MUTATE", { state: "projects", data: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    getprojectIssues: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", SetupConstants.projectIssues)
        .then((res) => {
          commit("MUTATE", { state: "projectIssues", data: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    getAssignees: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", SetupConstants.assignees)
        .then((res) => {
          commit("MUTATE", { state: "assignees", data: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    getReporters: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", SetupConstants.reporters)
        .then((res) => {
          commit("MUTATE", { state: "reporters", data: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    getClients: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", SetupConstants.clients)
        .then((res) => {
          commit("MUTATE", { state: "clients", data: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
    getModules: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", SetupConstants.modules)
        .then((res) => {
          commit("MUTATE", { state: "modules", data: res.data.data });
          commit("Dashboard/SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          Event.$emit("ApiError", error.response.data.message);
          commit("Dashboard/SET_LOADING", false, { root: true });
        });
    },
  },
};
