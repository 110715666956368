<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog v-model="dialog" width="1100" persistent>
    <v-card outlined v-if="displayIssue">
      <v-card-title class="text-h4 font-weight-black">
        <v-tooltip
          top
          :disabled="selectedIssue.status === 'Closed'"
          v-if="!editTitle"
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="text-capitalize"
              style="word-break: break-word; cursor: pointer"
              @click="selectedIssue.status !== 'Closed' ? editing() : editTitle"
              v-html="selectedIssue.title"
            />
          </template>

          <span>Click to edit</span>
        </v-tooltip>

        <v-col cols="6" v-if="editTitle" class="editor">
          <v-text-field
            ref="titleInput"
            v-model="selectedIssue.title"
            label="Title"
            dense
            filled
          />

          <div class="d-flex">
            <v-spacer />
            <v-icon
              :size="22"
              class="mr-3"
              color="primary"
              @click="(editTitle = false), selfUpdate()"
            >
              fa fa-check
            </v-icon>

            <v-icon
              class="ml-3"
              :size="22"
              color="error"
              @click="editTitle = false"
            >
              fa fa-ban
            </v-icon>
          </div>
        </v-col>
        <v-spacer />
        <v-btn icon @click="closeDialog" color="error">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-card-title>
      <v-row no-gutters>
        <v-col cols="7">
          <div>
            <v-chip
              class="ma-2"
              @click="$refs.uploader.$refs.input.click()"
              label
              ><v-icon class="mx-2">fa fa-paperclip</v-icon> Attach
            </v-chip>
            <v-chip class="ma-2" label @click="addChildIssue = true" ripple
              ><v-icon class="mx-2">account_tree</v-icon> Add a Child Issue
            </v-chip>
          </div>
          <v-file-input
            accept="*"
            ref="uploader"
            hide-input
            multiple
            chips
            prepend-icon=""
            @change="uploadFile"
          />
          <!--Description Begin-->

          <v-col cols="6" class="mt-n4">
            <span
              class="text-capitalize primary--text mb-2"
              v-html="selectedIssue.description"
            >
            </span>
          </v-col>

          <!--Description End-->
          <!--Description Button  Begin-->

          <v-col cols="12" class="mt-n4">
            <v-btn
              v-if="!toggle"
              depressed
              block
              @click="toggle = true"
              class="mr-3"
            >
              {{
                selectedIssue.description
                  ? "Edit description"
                  : "Add A description"
              }}
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="toggle">
            <Editor
              class="issue-editor mt-n3 mr-3"
              v-model="description"
              :editorToolbar="customToolbar"
            />

            <div class="d-flex mb-n4 mr-3">
              <v-spacer />
              <v-icon
                :size="20"
                class="ml-3 mr-3 mt-2"
                color="primary"
                @click="selfUpdate()"
              >
                fa fa-check
              </v-icon>

              <v-icon
                class="ml-2 mt-2"
                :size="20"
                color="error"
                @click="toggle = false"
              >
                fa fa-ban
              </v-icon>
            </div>
          </v-col>

          <v-col
            cols="12"
            v-if="addChildIssue || selectedIssue.childIssues.length !== 0"
          >
            <ChildIssues
              :issue="selectedIssue"
              :displayChildForm="addChildIssue"
              :list="assignees"
              @closeChildIssue="closeChildIssue"
            />
          </v-col>

          <!--Image Preview Begin-->
          <v-col
            cols="11"
            :class="selectedIssue.childIssues.length !== 0 ? 'mt-n4' : 'mt-0'"
            v-if="selectedIssue.projectAttachment.length !== 0"
          >
            <span class="text-button font-weight-bold">Attachments</span>

            <div class="d-flex overflow-auto ml-5">
              <div
                v-for="(item, index) in selectedIssue.projectAttachment"
                :key="index"
              >
                <v-chip
                  class="ma-2"
                  :color="
                    getFileExtension(item.attachment) === 'fa fa-file'
                      ? 'red'
                      : 'blue'
                  "
                  label
                  text-color="white"
                >
                  <div>
                    <v-chip
                      class="ma-2"
                      :color="
                        getFileExtension(item.attachment) === 'fa fa-file'
                          ? 'red'
                          : 'blue'
                      "
                      label
                      text-color="white"
                      @click="previewAttach(item)"
                    >
                      <v-icon left>
                        {{ getFileExtension(item.attachment) }}
                      </v-icon>
                      {{ item.fileName }}</v-chip
                    >
                  </div>
                  <div>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="ml-0"
                          @click="deleteAttachment(item)"
                          x-small
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          fa fa-trash
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </v-chip>
              </div>
            </div>
          </v-col>

          <!--Image Preview End-->
          <!--Comments and Log Begin-->
          <v-col>
            <div class="">
              <span class="text-button font-weight-bold mr-2">STATUS:</span>

              <v-btn-toggle dense>
                <v-btn
                  small
                  :color="!viewComments ? '' : 'primary white--text'"
                  value="left"
                  @click="viewComments = true"
                >
                  comments
                </v-btn>
                <v-btn
                  small
                  :color="viewComments ? '' : 'primary white--text'"
                  value="Upload from Excel"
                  @click="viewComments = false"
                >
                  Activity
                </v-btn>
              </v-btn-toggle>
            </div>
            <issue-comments :Comments="selectedIssue" v-if="viewComments" />
            <IssueLog
              :ticketLog="selectedIssue.issueChangeLogs"
              v-if="!viewComments"
            />
          </v-col>

          <!--Comments and Log End-->
        </v-col>

        <v-col class="text-button">
          <div class="d-flex">
            <v-chip
              label
              class="mt-0"
              :color="`${issueStatusColor(
                selectedIssue.status ? selectedIssue.status : 'N/A'
              )} lighten-5`"
              :class="`${issueStatusColor(
                selectedIssue.status ? selectedIssue.status : 'N/A'
              )}--text text--darken-4 text-capitlize`"
              >{{ selectedIssue.status ? selectedIssue.status : "N/A" }}</v-chip
            >

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="text-body-1 ml-6"
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  update Status

                  <v-icon small color="white" class="ml-2"
                    >fa fa-angle-down</v-icon
                  >
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group v-model="test">
                  <v-list-item v-for="(item, index) in statuses" :key="index">
                    <v-list-item-content
                      @click="updateTicketStatus(item.description)"
                    >
                      <v-list-item-title>{{ item.code }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
          <div class="mt-3">
            <div class="text-overline">
              Remaining Days

              <v-chip
                :color="diffInDays() <= 5 ? 'red' : 'blue'"
                small
                class="white--text ml-3"
                size="30"
                ><span class="text-h6">{{ diffInDays() }} </span></v-chip
              >
            </div>
          </div>
          <v-card class="mr-3 mt-3" max-width="800" height="300" outlined>
            <v-row class="mt-3 text-overline">
              <v-col cols="3"><span class="ml-3 mt-n2">Project </span> </v-col>
              <v-col cols="7">
                <v-chip color="red" small class="white--text ml-3" size="30"
                  ><span class=""
                    >{{ selectedIssue.projectsApi.description }}
                  </span></v-chip
                >
              </v-col>
              <v-col cols="3"><span class="ml-3 mt-n2">Assignee </span> </v-col>
              <v-col cols="7">
                <v-autocomplete
                  v-model="formData.assignee"
                  :items="assignees ? assignees : []"
                  item-text="name"
                  item-value="code"
                  single-line
                  filled
                  class="issue-text-field mt-n2"
                  dense
                  v-on:change="assignIssue()"
                  height="10"
                >
                  <template v-slot:label>
                    <span class="text-overline">assignee</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3" class="mt-n6"
                ><span class="ml-3 mt-n2">Due Date </span>
              </v-col>
              <v-col cols="7" class="mt-n6">
                <v-menu
                  ref="menu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="selectedIssue.dueDate"
                  transition="scale-transition"
                  left
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.dueDate"
                      prepend-inner-icon="fa fa-calendar"
                      readonly
                      menu
                      filled
                      single-line
                      dense
                      height="10"
                      class="issue-text-field mt-n2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span class="text-overline">Due Date</span>
                      </template>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="selectedIssue.dueDate"
                    no-title
                    scrollable
                    min="2016-06-15"
                    max="2024-01-31"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.menu.save(selectedIssue.dueDate), selfUpdate()
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="3" class="mt-n6"
                ><span class="ml-3">Reporter </span>
              </v-col>
              <v-col cols="7" class="mt-n6">
                <div class="d-flex">
                  <v-avatar
                    class="mr-2 white--text"
                    :size="30"
                    :color="
                      selectedIssue.reporter !== ''
                        ? userColor(
                            selectedIssue.reporter ? selectedIssue.reporter : ''
                          )
                        : 'primary'
                    "
                  >
                    <v-img
                      v-if="selectedIssue.reporter"
                      :src="getReporterAvatar(selectedIssue.reporter)"
                      contain
                    />
                  </v-avatar>

                  <span class="text-body-2 mt-1">
                    {{ getReporterName(selectedIssue.reporter) }}
                  </span>
                </div>
              </v-col>
              <v-col cols="3"><span class="ml-3 mt-n2">Priority </span> </v-col>
              <v-col cols="7">
                <v-autocomplete
                  v-model="formData.priorities"
                  :items="priorities ? priorities : []"
                  item-text="description"
                  item-value="code"
                  single-line
                  filled
                  class="issue-text-field mt-n2"
                  dense
                  v-on:change="selfUpdate()"
                  height="10"
                >
                  <template v-slot:label>
                    <span class="text-overline">priority</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3" class="mt-n6"><span class="ml-3"></span> </v-col>
              <v-col cols="7" class="mt-n6">
                <!-- {{ selectedIssue.productModules.name }} -->
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <div id="editorInput" style="display: none">
        <div
          style="display: none"
          class="ql-editor"
          v-html="description"
          id="quizOutput"
        ></div>
      </div>

      <DialogSpinner />
    </v-card>
    <v-card v-else class="overflow-hidden">
      <v-row>
        <v-col cols="12" md="8">
          <v-card flat>
            <v-skeleton-loader
              type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line"
            ></v-skeleton-loader>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card flat>
            <v-skeleton-loader
              type="card-heading, list-item-two-line, list-item-two-line, list-item-three-line"
            ></v-skeleton-loader>
          </v-card> </v-col
      ></v-row>
    </v-card>
    <image-pdf-preview
      :imagetoPreview="imagetoPreview"
      :dialog="imageDialog"
      v-on:close-dialog="closeImageDialog"
    />
  </v-dialog>
</template>

<script>
import { VueEditor as Editor } from "vue2-editor";
import issueMixin from "../issueMixin";
import ChildIssues from "./ChildIssues";
import DialogSpinner from "@/packages/dashboard/plugins/loader/views/DialogSpinner.vue";
import { AuthService } from "@/packages/dashboard/modules/auth";
import IssueComments from "./IssueComments.vue";
import IssueLog from "./IssueLog.vue";
import ImagePdfPreview from "./ImagePdfPreview.vue";
import helpers from "@/packages/dashboard/utils/helpers/helper.js";
import EditorMixin from "../EditorMixin";

export default {
  name: "ViewSelectedIssue",
  mixins: [issueMixin, EditorMixin],
  components: {
    Editor,
    ChildIssues,
    DialogSpinner,
    IssueComments,
    IssueLog,
    ImagePdfPreview,
  },
  data: function () {
    return {
      isValid: false,
      menu: false,
      dialog: true,
      commentSection: true,
      dateMenu: false,

      url: process.env.VUE_APP_TEMP_URL,
      addChildIssue: false,
      displayButtons: false,
      editTitle: false,
      test: "",
      formData: {
        description: "",
        issueType: "",
        attachment: "",
        projectCode: "",
        status: "",
        assignee: "",
        dueDate: "",
        priorities: "",
        reporter: "",
        comment: "",
      },
      toggle: false,
      viewComments: true,
      imagetoPreview: {},
      imageDialog: false,
      displayAssignee: false,
      description: "",
    };
  },
  mounted() {
    Event.$on("savingUser", (value) => {
      this.saving = value;
    });
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Ticket/getIssue", to.params.id);
      // v.$store.dispatch("Setup/getIssueStatus");
      // v.$store.dispatch("Setup/getAssignees");
    });
  },
  computed: {
    customToolbar() {
      return [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["code-block"],
      ];
    },
    routeParams() {
      return this.$route.params.id;
    },
    displayIssue() {
      return this.selectedIssue.issueCode === this.routeParams;
    },

    user() {
      return AuthService.user;
    },

    assignees: function () {
      return this.$store.getters["Setup/setupGetters"]("assignees");
    },
    selectedIssue: function () {
      return this.$store.getters["Ticket/issueGetters"]("selectedIssue");
    },
    reporters: function () {
      return this.$store.getters["Setup/setupGetters"]("reporters");
    },
    priorities: function () {
      return this.$store.getters["Setup/setupGetters"]("priorities");
    },

    statuses: function () {
      return this.$store.getters["Setup/setupGetters"]("status");
    },
    getSelectedStatus: function () {
      const data = this.statuses[`${this.test}`].description;
      return data;
    },
  },
  methods: {
    diffInDays() {
      if (this.formData.dueDate === "0001-01-01") return "N/A";
      const date = new Date();

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      // This arrangement can be altered based on how we want the date's format to appear.
      const currentDate = `${year}-${month}-${day}`;
      const dt1 = new Date(currentDate);
      const dt2 = new Date(this.formData.dueDate);
      return Math.floor(
        (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
          Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
          (1000 * 60 * 60 * 24)
      );
    },
    closeChildIssue(val) {
      this.addChildIssue = val;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    uploadFile: async function (value) {
      if (value) {
        this.formData.attachments = value;
        const attachments = this.formData.attachments;
        const data = {
          attachments: attachments,
          issueCode: this.selectedIssue.issueCode,
        };
        const formData = helpers.prepareFormData(data);
        attachments.forEach((file) => {
          formData.append("attachments[]", file);
        });
        const allData = {
          sentData: formData,
          issueCode: this.selectedIssue.issueCode,
        };

        this.$store.dispatch("Ticket/updateAttachment", allData);
      }
    },
    getReporterName(val) {
      if (val) {
        const data = this.reporters.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].name;
        } else {
          return "";
        }
      } else {
        return "Unassigned";
      }
    },
    getReporterAvatar(val) {
      if (val) {
        const data = this.reporters.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].avatar
            ? `data:image/png;base64,${data[0].avatar}`
            : require("@/assets/avatar.jpeg");
        } else {
          return "";
        }
      }
    },
    getFileExtension(val) {
      const Ext = val.split(".").pop();
      if (Ext === "pdf") {
        return "fa fa-file";
      } else {
        return "fa fa-file-image";
      }
    },
    previewAttach(val) {
      this.imageDialog = true;
      const Ext = val.attachment.split(".").pop();
      const data = {
        previewDoc: val.attachment,
        Extension: Ext,
        fileName: val.fileName,
      };
      this.imagetoPreview = data;
    },
    closeImageDialog: function (val) {
      this.imageDialog = val;
    },
    deleteAttachment(item) {
      const data = {
        fileName: item.fileName,
        lineNo: item.lineNo,
        no: item.no,
        tableID: item.tableID,
        ID: item.ID,
      };
      this.$store.dispatch("Ticket/deleteAttachment", data);
    },
    editing() {
      this.editTitle = !this.editTitle;
      this.$nextTick(() => {
        this.$refs.titleInput.focus();
      });
    },
  },

  watch: {
    selectedIssue: {
      handler: function () {
        this.formData = this.selectedIssue;
        // this.date = this.selectedIssue.dueDate;
      },
    },
  },
};
</script>

<style>
.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
.detail-text {
  color: #274472;
}
.input__label {
  font-size: small;
  opacity: 1;
}
.issue-editor > .ql-container > .ql-editor {
  min-height: 70px !important;
  margin-top: -7px;
}
.issue-text-field.v-text-field > .v-input__control > .v-input__slot::before {
  border-style: none !important;
}
</style>
