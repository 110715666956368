import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{attrs:{"app":"","elevation":"0","color":"topbar","height":"50%"}},[_c(VBtn,{attrs:{"icon":"","color":"topbarText"},on:{"click":_vm.closeDrawer}},[_c(VIcon,[_vm._v("apps")])],1),_c('div',{staticClass:"topbarText--text text-body-1 text-no-wrap"},[_vm._v(" "+_vm._s(_vm.company.displayName)+" "),_c('span',{staticClass:"mx-2"},[_vm._v("|")]),_vm._v(" Issue Management ")]),_c(VSpacer),_c(VBadge,{staticClass:"mx-2",attrs:{"top":"","color":"warning","offset-y":"23","offset-x":"20","overlap":"","content":_vm.messages}},[_c(VBtn,{attrs:{"icon":"","link":"","to":{ name: 'mailList' },"elevation":"0"}},[_c(VIcon,{attrs:{"large":"","color":"secondary"}},[_vm._v("mail")])],1)],1),_c(VMenu,{attrs:{"offset-y":"","left":"","nudge-bottom":"14","min-width":"230","content-class":"user-profile-menu-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBadge,{staticClass:"ms-4",attrs:{"bottom":"","color":"warning","overlap":"","offset-x":"12","offset-y":"12","dot":""}},[_c(VAvatar,_vm._g(_vm._b({attrs:{"size":"40px"}},'v-avatar',attrs,false),on),[_c(VImg,{attrs:{"src":_vm.profile.avatar
                ? _vm.profilePicture
                : require('@/assets/avatar.jpeg')}})],1)],1)]}}])},[_c(VList,[_c('div',{staticClass:"pb-3 pt-2"},[_c(VBadge,{staticClass:"ms-4",attrs:{"bottom":"","color":"warning","overlap":"","offset-x":"12","offset-y":"12","dot":""}},[_c(VAvatar,{attrs:{"size":"40px"}},[_c(VImg,{attrs:{"src":_vm.profile.avatar
                  ? _vm.profilePicture
                  : require('@/assets/avatar.jpeg')}})],1)],1),_c('div',{staticClass:"d-inline-flex flex-column justify-center ms-3",staticStyle:{"vertical-align":"middle"}},[_c('span',{staticClass:"text--primary font-weight-semibold mb-n1"},[_c('span',[_vm._v(_vm._s(_vm.profile ? ((_vm.profile.first_name) + " " + ("") + " " + (_vm.profile.last_name)) : ""))])]),_c('small',{staticClass:"text--disabled text-capitalize mt-1"},[_vm._v(_vm._s(_vm.profile ? _vm.profile.user_type : ""))])])],1),_c(VDivider),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.editProfile()}}},[_c(VListItemIcon,{staticClass:"me-2"},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Profile")])],1)],1),_c(VDivider,{staticClass:"my-2"}),_c(VListItem,{attrs:{"link":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c(VBtn,{staticClass:"account-btn",attrs:{"text":""},on:{"click":_vm.logout}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLogoutVariant))]),_vm._v("Logout")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }