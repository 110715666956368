export default [
  {
    action: "addNew",
    order: 3,
    icon: "fa-solid fa-add",
    disabled: false,
    iconColor: "primary",
    text: "Add New",
    subLinks: [
      {
        action: "addRole",
        order: 1,
        icon: "fa fa-unlock",
        disabled: false,
        text: "Role",
      },
      {
        action: "addPermission",
        order: 1,
        icon: "fa fa-unlock",
        disabled: false,
        text: "Permission",
      },
    ],
  },
];
