<template>
  <div>
    <div ref="pieChart" style="width: 30vw; height: 40vh"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "PieChart",
  props: {
    data: {
      required: true,
      type: Array,
    },
    currentData: {
      type: Function,
      required: true,
    },
  },
  methods: {
    drawChart() {
      const chart = echarts.init(this.$refs.pieChart);
      const uniqueDates = [...new Set(this.data.map((stat) => stat.date))];
      const groupedData = uniqueDates.map((date) => {
        return this.data.filter((stat) => {
          return stat.date === date;
        });
      });
      const currentDate = new Date().toISOString().slice(0, 10); // get the date in ISO format and slice the time part
      const usageToday = groupedData.filter((innerItem) => {
        return innerItem[0].date === currentDate;
      }).flat();
      this.currentData(usageToday);
      const seriesData = {
        name: usageToday.date,
        type: "pie",
        radius: "50%",
        center: ["50%", "50%"],
        data: usageToday.map((item) => {
          return {
            value: ((item.user_total_activities_per_day / item.total_activities_per_day) * 100).toFixed(2),
            name: item.user_id,
          };
        }),
        roseType: "radius",
        label: {
          color: "rgba(32, 51, 84, 0.8)",
        },
        labelLine: {
          lineStyle: {
            color: "rgba(32, 51, 84, 0.8)",
          },
          smooth: 0.2,
          length: 10,
          length2: 20,
        },
        itemStyle: {
          color: "#c23531",
          shadowBlur: 200,
          shadowColor: "rgba(32, 51, 84, 0.8)",
        },
        animationType: "scale",
        animationEasing: "elasticOut",
        animationDelay: function () {
          return Math.random() * 200;
        },
      };
      const option = {
        backgroundColor: "transparent",
        title: {
          text: "Daily usage on pie chart",
          left: "center",
          top: "auto",
          textStyle: {
            color: "rgba(32, 51, 84, 0.8)",
          },
        },
        tooltip: {
          trigger: "item",
        },
        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1],
          },
        },
        series: [seriesData],
      };
      chart.setOption(option);
    },
  },
  watch: {
    data: {
      handler(val) {
        if (val) this.drawChart();
      },
      deep: true,
    },
  },
};
</script>
