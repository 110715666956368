<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container fluid v-can="`'${PERMISSIONS.VIEW_USERS}'`">
    <v-row>
      <v-col cols="12">
        <v-card class="mt-3" flat>
          <v-card-text>
            <v-row class="mt-2 pa-2">
              <h3>System Users</h3>
              <v-spacer />
              <v-btn
                v-can="`'${PERMISSIONS.CREATE_USERS}'`"
                @click="redirectToUserCard()"
                color="primary"
                >Send Invitation to new User</v-btn
              >
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-data-table :headers="headers" :items="users" :items-per-page="5">
              <template v-slot:[`item.id`]="{ index }">
                <span>{{ index + 1 }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu open-on-hover>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="text-capitalize links"
                      text
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      <i class="fa-solid fa-ellipsis-vertical"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="deleteUser(item.id)"
                      v-can="`'${PERMISSIONS.DELETE_USERS}'`"
                      ><span
                        ><v-icon color="secondary" size="">mdi-delete</v-icon>
                        delete</span
                      ></v-list-item
                    >
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <UserManagementCard :dialog="dialog" v-on:close-dialog="closeDialog" />
  </v-container>
</template>

<script>
import Permissions from "@/shared/Permissions";
import UserManagementCard from "./UserManagementCard.vue";

export default {
  name: "UserManagementList",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("UserManagement/users");
    });
  },
  components: {
    UserManagementCard,
  },
  data: () => ({
    PERMISSIONS: Permissions,
    headers: [
      {
        text: "#S/N",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "First Name",
        align: "start",
        sortable: false,
        value: "first_name",
      },
      {
        text: "Last Name",
        align: "start",
        sortable: false,
        value: "last_name",
      },
      {
        text: "Email",
        align: "start",
        sortable: false,
        value: "email",
      },
      {
        text: "Phone",
        align: "start",
        sortable: false,
        value: "phone",
      },
      {
        text: "Role",
        align: "start",
        sortable: false,
        value: "user_type",
      },
      {
        text: "Company",
        align: "start",
        sortable: false,
        value: "company",
      },
      {
        text: "Company Email",
        align: "start",
        sortable: false,
        value: "company_email",
      },
      {
        text: "Address",
        align: "start",
        sortable: false,
        value: "physical_address",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dialog: false,
  }),
  computed: {
    users() {
      return this.$store.getters["UserManagement/userGetters"]("users");
    },
  },
  methods: {
    redirectToUserCard() {
      this.dialog = true;
    },
    closeDialog: function (val) {
      this.dialog = val;
    },
    deleteUser(val) {
      this.$store.dispatch("UserManagement/deleteUser", val);
    },
  },
};
</script>

<style scoped></style>
