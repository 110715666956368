<template>
  <!-- eslint-disable vue/valid-v-slot -->
  <v-container fluid v-can="`'${PERMISSIONS.VIEW_PERMISSIONS}'`">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-card-title class="headline">Access Permissions</v-card-title>
            <v-spacer />
            <!--Start permissions dialog-->
            <v-dialog
              v-model="dialog"
              width="500"
              v-can="`'${PERMISSIONS.CREATE_PERMISSIONS}'`"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  Add New Permission
                </v-btn>
              </template>

              <v-card flat>
                <v-card-title class="text-h5 grey lighten-2">
                  Create Permission
                </v-card-title>
                <v-card-text class="ma-3">
                  <v-form ref="permissionForm" v-model="isValid" :rules="rules">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="formData.name"
                          label="Permission Name"
                          outlined
                          dense
                          required
                          :rules="rules.name"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          label="Display Name"
                          outlined
                          placeholder="Name to be displayed to the user"
                          dense
                          v-model="formData.display_name"
                          required
                          :rules="rules.name"
                        />
                      </v-col>
                      <v-col cols="12" md="12" class="mt-n4">
                        <v-textarea
                          label="Description"
                          outlined
                          placeholder="What does the permission say to the user?"
                          dense
                          v-model="formData.description"
                          required
                          :rules="rules.name"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions v-can="`'${PERMISSIONS.CREATE_PERMISSIONS}'`">
                  <v-btn color="error" @click="dialog = false"> Cancel </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn v-if="!saving" color="primary" @click="savePermission">
                    Submit
                  </v-btn>
                  <v-btn v-if="saving" color="primary"> Processing... </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!--END permissions dialog-->
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="3"
                v-bind:key="perm.id"
                v-for="perm in permissions"
              >
                <v-checkbox
                  class="text-capitalize"
                  :label="perm.name"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Permissions from "@/shared/Permissions";

export default {
  name: "AccessPermission",
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("AccessControl/permissions");
    });
  },
  data: () => ({
    PERMISSIONS: Permissions,
    dialog: false,
    isValid: false,
    saving: false,
    formData: {
      name: "",
      display_name: "",
      description: "",
    },
    rules: {
      name: [(v) => !!v || "Name is required"],
      display_name: [(v) => !!v || "Display Name is required"],
      description: [(v) => !!v || "Description is required"],
    },
  }),
  mounted() {
    Event.$on("savingPermission", (value) => {
      this.saving = value;
    });
  },
  computed: {
    permissions() {
      return this.$store.getters["AccessControl/accessGetters"]("permissions");
    },
  },
  methods: {
    //save permission
    savePermission() {
      this.saving = true;
      if (!this.isValid) {
        this.saving = false;
        this.$refs.permissionForm.validate();
      } else {
        this.$store.dispatch("AccessControl/savePermission", {
          ...this.formData,
        });
      }
    },
  },
};
</script>

<style scoped></style>
