import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"mt-4",attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"text-h5 secondary--text font-weight-bold"},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi mdi-chart-line")]),_c('span',[_vm._v("Issues Per Project")]),_c(VSpacer),_c(VBtn,{staticClass:"primary text-body-1 text-capitalize",attrs:{"to":"/tickets/issues"}},[_vm._v("View All Issues")])],1),_c(VDivider),_c(VCardText,{staticClass:"d-flex text-center flex-wrap"},_vm._l((_vm.getStatusCount),function(desc,i){return _c('div',{key:i,staticClass:"ma-2"},[_c(VChip,{attrs:{"label":"","color":((_vm.issueStatusColor(desc.status.toUpperCase())) + " lighten-5")}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(desc.status))]),_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("|")]),_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v(_vm._s(desc.count)+" ")])])],1)}),0),_c(VDivider),_c(VDataTable,{staticClass:"elevation-0",attrs:{"headers":_vm.projectsHeaders,"items":_vm.projects,"item-key":"code","expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.issues",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.clickColumn(item).length)+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.expandableHeaders.length}},[_c(VContainer,[_c(VResponsive,[_c(VDataTable,{attrs:{"headers":_vm.expandableHeaders,"items":_vm.clickColumn(item),"items-per-page":5},scopedSlots:_vm._u([{key:"item.issueType",fn:function(ref){
var item = ref.item;
return [_c(VChip,{class:((_vm.issueTypeColor(
                      item.issueType.toLowerCase()
                    )) + "--text text--darken-4 text-capitalize"),attrs:{"label":"","small":"","color":((_vm.issueTypeColor(
                      item.issueType.toLowerCase()
                    )) + " lighten-5")}},[_vm._v(_vm._s(item.issueType))])]}},{key:"item.title",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"text-capitalize primary--text pt-3 text-truncate",staticStyle:{"max-width":"350px"},domProps:{"innerHTML":_vm._s(item.title)}})]}},{key:"item.assignee",fn:function(ref){
                    var item = ref.item;
return [(item.assignee)?_c('div',{staticClass:"d-flex"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"mr-2 white--text",attrs:{"size":24,"color":item.assignee !== ''
                              ? _vm.userColor(item.assignee)
                              : 'primary'}},'v-avatar',attrs,false),on),[(item.assignee)?_c(VImg,{attrs:{"src":_vm.getAssigneeAvatar(item.assignee),"contain":""}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getAsigneeName(item.assignee)))])]),_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getAsigneeName(item.assignee))+" ")])],1):_c('div',[_c('span',{staticClass:"text-capitalize"},[_vm._v("Unassigned")])])]}},{key:"item.reporter",fn:function(ref){
                              var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c(VAvatar,_vm._g(_vm._b({staticClass:"mr-2 white--text",attrs:{"size":24,"color":item.reporter !== ''
                              ? _vm.userColor(item.reporter)
                              : 'primary'}},'v-avatar',attrs,false),on),[(item.reporter)?_c(VImg,{attrs:{"src":_vm.getReporterAvatar(item.reporter),"contain":""}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getReporterName(item.reporter)))])]),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.getReporterName(item.reporter)))])],1)]}},{key:"item.priorities",fn:function(ref){
                              var item = ref.item;
return [_c(VChip,{class:item.priorities
                        ? ((_vm.issuePriorityColor(
                            item.priorities.toUpperCase()
                          )) + "--text text--darken-4 text-capitlize")
                        : '',attrs:{"label":"","small":"","dark":"","color":item.priorities
                        ? ((_vm.issuePriorityColor(
                            item.priorities.toUpperCase()
                          )) + " lighten-5")
                        : ''}},[_vm._v(_vm._s(item.priorities ? item.priorities.toUpperCase() : "None"))])]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_c(VChip,{class:((_vm.issueStatusColor(
                      item.issueStatusColor
                    )) + "--text text--darken-4 text-capitlize"),attrs:{"label":"","small":"","color":((_vm.issueStatusColor(item.status)) + " lighten-5")}},[_vm._v(_vm._s(item.status))])]}},{key:"item.productModules",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.moduleCode)+" ")]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',[_c(VIcon,_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.viewIssue(item)}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("view")])])]}}],null,true)})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }