import call from "@/service/http";
import constants from "./usageConstants";
export default {
  namespaced: true,
  state: {
    usageStats: [],
    activities: [],
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },
  },
  getters: {
    usageGetters: (state) => (val) => state[val],
  },
  actions: {
    fetchStats: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", constants.stats)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "usageStats", data: res.data.data });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
    fetchActivities: ({ commit }) => {
      commit("Dashboard/SET_LOADING", true, { root: true });
      call("get", constants.statsWithActivities)
        .then((res) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          commit("MUTATE", { state: "usageStats", data: res.data.data });
        })
        .catch((error) => {
          commit("Dashboard/SET_LOADING", false, { root: true });
          Event.$emit("ApiError", error.response.data.message);
        });
    },
  },
};
