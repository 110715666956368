import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('div',[(_vm.ticketLog.length !== 0)?_c(VCard,{staticClass:"overflow-auto",attrs:{"flat":"","height":"150","width":"800"}},[_c(VList,{staticClass:"overflow-auto"},_vm._l((_vm.ticketLog),function(item,i){return _c(VListItem,{key:i},[_c(VListItemContent,{attrs:{"cols":"12"}},[_c(VListItemTitle,[_c('span',{staticClass:"pa-2"},[_vm._v(_vm._s(item.fieldCaption === "Status" ? "Updated" : "Changed"))]),_c('span',{staticClass:"font-weight-bold",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(item.fieldCaption)+" ")]),_c('span',{staticClass:"pa-2"},[_vm._v("on")]),_vm._v(" "+_vm._s(_vm.formatDate(item.dateAndTime))+" "),_c('span',{staticClass:"pa-2"},[_vm._v("at")]),_vm._v(" "+_vm._s(_vm.formatTime(item.time))+" ")]),_c(VListItemSubtitle,{staticClass:"mt-0"},[(item.oldValue !== '')?_c(VChip,{staticClass:"text-capitlize text-decoration-line-through",attrs:{"x-small":"","label":""}},[_vm._v(" "+_vm._s(_vm.checkValue(item.oldValue))+" ")]):_vm._e(),(item.oldValue === '')?_c('span',{staticClass:"pa-2"}):_c(VIcon,{staticClass:"pa-2"},[_vm._v("fa fa-long-arrow-right")]),_c(VChip,{staticClass:"text-capitlize`",attrs:{"x-small":"","label":""}},[_vm._v(" "+_vm._s(item.newValue)+" ")])],1)],1)],1)}),1)],1):_vm._e(),(_vm.ticketLog.length === 0)?_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_vm._v(" No Log Available for this Issue ")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }