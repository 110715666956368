import usageRoutes from "./routes";
import usageStore from "./store";
import usageLinks from "./links";
export default {
  install(Vue, options) {
    if (options.router) {
      options.router.addRoute(...usageRoutes);
    }
    if (options.store) {
      options.store.registerModule("Usage", usageStore);
      options.store.dispatch("Dashboard/setLinks", usageLinks);
    }
  },
};
