import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","width":"70%"},model:{value:(_vm.mailDialog),callback:function ($$v) {_vm.mailDialog=$$v},expression:"mailDialog"}},[_c(VCard,{staticClass:"mx-auto"},[_c(VCardTitle,{staticClass:"mail-title"},[(_vm.$route.query.reply)?_c('span',[_c(VIcon,{staticClass:"mr-2"},[_vm._v("reply")]),_c('span',[_vm._v("Reply Email")])],1):(_vm.$route.query.forward)?_c('span',[_c(VIcon,{staticClass:"mr-2"},[_vm._v("forward")]),_vm._v(" Forward Email ")],1):_c('span',[_vm._v("Compose Email")]),_c(VSpacer)],1),_c(VDivider),_c(VCardText,[_c(VForm,{ref:"mailForm",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c(VTextField,{staticClass:"mt-3",attrs:{"label":"Subject","placeholder":"Enter email subject","outlined":"","dense":"","persistent-placeholder":"","rules":[_vm.rules.required]},model:{value:(_vm.formData.subject),callback:function ($$v) {_vm.$set(_vm.formData, "subject", $$v)},expression:"formData.subject"}}),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VAutocomplete,{staticClass:"mr-1",attrs:{"items":_vm.clients,"placeholder":"Select To Client","persistent-placeholder":"","rules":[_vm.rules.required],"label":"To","clearable":"","outlined":"","dense":"","item-text":"email","item-value":"email"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" contact_mail ")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({attrs:{"ripple":""}},'v-list-item',attrs,false),on),[_c(VAvatar,{staticClass:"indigo lighten-4 indigo--text text--darken-4 mr-3",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(("" + (item.first_name[0] || "") + (item.last_name[0] || "")))+" ")]),_c(VListItemContent,[_c(VListItemTitle,[_c('span',[_vm._v(" "+_vm._s(item.first_name + " " + item.last_name)+" "),_c(VChip,{staticClass:"indigo--text text--darken-4 mx-2",attrs:{"left":"","x-small":"","color":"indigo lighten-4"}},[_vm._v(" "+_vm._s(item.user_code)+" ")])],1)]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.email))])],1),_c(VListItemAction,[_c(VChip,{attrs:{"small":"","label":"","color":"indigo lighten-4"}},[_vm._v(" "+_vm._s(item.company)+" ")])],1)],1)]}}]),model:{value:(_vm.formData.to),callback:function ($$v) {_vm.$set(_vm.formData, "to", $$v)},expression:"formData.to"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VAutocomplete,{staticClass:"ml-1",attrs:{"items":_vm.clients,"placeholder":"Select CC Client (Optional)","persistent-placeholder":"","label":"CC (Optional)","clearable":"","outlined":"","multiple":"","chips":"","dense":"","item-text":"email","item-value":"email"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c(VIcon,{attrs:{"color":"primary"}},[_vm._v(" contact_mail ")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItem,_vm._g(_vm._b({attrs:{"ripple":""}},'v-list-item',attrs,false),on),[_c(VAvatar,{staticClass:"indigo lighten-4 indigo--text text--darken-4 mr-3",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(("" + (item.first_name[0] || "") + (item.last_name[0] || "")))+" ")]),_c(VListItemContent,[_c(VListItemTitle,[_c('span',[_vm._v(" "+_vm._s(item.first_name + " " + item.last_name)+" "),_c(VChip,{staticClass:"indigo--text text--darken-4 mx-2",attrs:{"left":"","x-small":"","color":"indigo lighten-4"}},[_vm._v(" "+_vm._s(item.user_code)+" ")])],1)]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.email))])],1),_c(VListItemAction,[_c(VChip,{attrs:{"small":"","label":"","color":"indigo lighten-4"}},[_vm._v(" "+_vm._s(item.company)+" ")])],1)],1)]}}]),model:{value:(_vm.formData.cc),callback:function ($$v) {_vm.$set(_vm.formData, "cc", $$v)},expression:"formData.cc"}})],1)],1),_c('div',{staticClass:"d-flex flex-column mt-n3"},[_c('span',[_vm._v("Body")]),_c('Editor',{staticClass:"editor",attrs:{"editorToolbar":_vm.customToolbar},model:{value:(_vm.formData.body),callback:function ($$v) {_vm.$set(_vm.formData, "body", $$v)},expression:"formData.body"}})],1)],1)],1),_c(VCardActions,{staticClass:"mail-actions"},[_c(VBtn,{staticClass:"deep-orange lighten-4 deep-orange--text text--darken-4",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{staticClass:"mx-2"},[_vm._v("arrow_back")]),_vm._v(" Go Back ")],1),_c(VSpacer),_c(VBtn,{staticClass:"primary white--text text-body-1 text-capitalize",on:{"click":_vm.sendMail}},[_vm._v(" Send Email "),_c(VIcon,{staticClass:"mx-2"},[_vm._v("send")])],1)],1),_c('dialog-spinner-vue')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }