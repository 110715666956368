import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
/** misc npm packages */
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { registerLicense } from "@syncfusion/ej2-base";
registerLicense(
  "ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1iUX9XdHFRR2JVVUc="
);
// import { GanttPlugin } from "@syncfusion/ej2-vue-gantt";
import VueEllipseProgress from "vue-ellipse-progress";
/** global mixins */
import listener from "@/packages/dashboard/mixin/listeners/listener";
/** app global utils */
import { navigationType } from "@/packages/dashboard/utils/check-page-reload";
/** app plugins */
import vuetify from "./plugins/vuetify";
import appAlert from "@/packages/dashboard/plugins/appAlert";
import { filters } from "@/packages/dashboard/utils";
import confirmation from "./packages/dashboard/plugins/confirmtion";
import verify from "./packages/dashboard/plugins/verify";
/** app packages */
import dashboard from "./packages/dashboard";
import tickets from "./packages/tickets";
// import projects from "./packages/projects";
import accessControl from "@/packages/access-control";
import setup from "./packages/setup";
import shared from "@/shared";
import userManagement from "@/packages/user-management";
/** sockets package */
import sockets from "@/packages/sockets";
import usageStats from "@/packages/usageStats";
import mail from "@/packages/mail";

Vue.config.productionTip = false;

window.Event = new Vue();

const options = {
  store,
  router,
};

filters.forEach((f) => {
  Vue.filter(f.name, f.execute);
});
//directive
Vue.use(shared);
Vue.use(VueEllipseProgress);
Vue.use(Toast);
Vue.use(confirmation);
Vue.use(appAlert);
Vue.use(VueI18n);
Vue.use(verify, options);
Vue.use(dashboard, options);
Vue.use(accessControl, options);
Vue.use(userManagement, options);
Vue.use(tickets, options);
Vue.use(usageStats, options);
Vue.use(mail, options);
// Vue.use(projects, options);
Vue.use(setup, options);
Vue.use(navigationType);
Vue.use(sockets, options);

// Vue.use(GanttPlugin);

new Vue({
  router,
  store,
  vuetify,
  mixins: [listener],
  render: (h) => h(App),
}).$mount("#app");
