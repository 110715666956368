<template>
  <v-footer app :inset="true" elevation="12" class="overline">
    <span style="font-size: 0.625rem"> Issue Management </span>
    <v-spacer />

    <span style="font-size: 0.625rem">
      {{ appName }} © {{ new Date().getFullYear() }}
    </span>

    <v-spacer />

    <v-btn x-small text href="https://kinetics.co.ke/" target="_blank">
      Kinetics Technology LTD
      <v-icon x-small right> mdi-open-in-new </v-icon>
    </v-btn>
  </v-footer>
</template>

<script>
import { appName } from "../environment";

export default {
  name: "Footer",
  props: {
    opacity: {
      type: String,
      default: "1",
    },
  },

  computed: {
    appName() {
      return appName;
    },

    intakePeriod() {
      return this.$store.getters["Dashboard/intakePeriods"].filter(
        (period) => period.current
      )[0];
    },
  },
};
</script>

<style scoped></style>
