import DashboardLayout from "@/packages/dashboard/modules/dashboard/views/dashboardLayout";
import Auth from "@/packages/dashboard/modules/auth/middleware/Auth";
// import AdminMiddleware from "../auth/middleware/AdminMiddleware";
// import User from "../auth/middleware/User";
import ChangeStatus from "./components/ChangeStatus";
import Issues from "./views/Issues";
import IssueList from "./components/IssueList";
import ViewSelectedIssue from "./components/ViewSelectedIssue";

export default [
  {
    path: "/tickets",
    component: DashboardLayout,
    children: [
      {
        path: "",
        redirect: "/tickets/issues",
      },
      {
        path: "issues",
        name: "issues",
        component: Issues,
        children: [
          {
            path: "",
            redirect: "/tickets/issues/list",
          },
          {
            path: "list/:code?",
            name: "issueList",
            components: {
              view: IssueList,
            },
            meta: { middleware: [Auth /** , User, AdminMiddleware */] },
          },
          {
            path: "change-status",
            name: "changeStatus",
            components: {
              view: IssueList,
              action: ChangeStatus,
            },
            meta: { middleware: [Auth /** , User, AdminMiddleware */] },
          },
          {
            path: "view-issue/:id",
            name: "ViewSelectedIssue",
            components: {
              view: IssueList,
              action: ViewSelectedIssue,
            },
            meta: { middleware: [Auth /** , User, AdminMiddleware */] },
          },
        ],
      },
    ],
  },
];
