<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container fluid>
    <v-card class="mt-4" flat>
      <v-card-title class="text-h5 secondary--text font-weight-bold">
        <v-icon class="mr-2">mdi mdi-chart-line</v-icon>
        <span>Issues Per Project</span>
        <v-spacer />
        <v-btn class="primary text-body-1 text-capitalize" to="/tickets/issues"
          >View All Issues</v-btn
        >
      </v-card-title>
      <v-divider />
      <v-card-text class="d-flex text-center flex-wrap">
        <div class="ma-2" v-for="(desc, i) in getStatusCount" :key="i">
          <v-chip
            label
            :color="`${issueStatusColor(desc.status.toUpperCase())} lighten-5`"
          >
            <span class="mr-2">{{ desc.status }}</span>
            <span class="font-weight-bold mr-2">|</span>
            <span class="font-weight-bold mr-2">{{ desc.count }} </span>
          </v-chip>
        </div>
      </v-card-text>
      <v-divider />
      <v-data-table
        :headers="projectsHeaders"
        :items="projects"
        item-key="code"
        class="elevation-0"
        :expanded.sync="expanded"
        show-expand
      >
        <template v-slot:item.issues="{ item }">
          {{ clickColumn(item).length }}
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="expandableHeaders.length">
            <v-container>
              <v-responsive>
                <v-data-table
                  :headers="expandableHeaders"
                  :items="clickColumn(item)"
                  :items-per-page="5"
                >
                  <template v-slot:item.issueType="{ item }">
                    <v-chip
                      label
                      small
                      :color="`${issueTypeColor(
                        item.issueType.toLowerCase()
                      )} lighten-5`"
                      :class="`${issueTypeColor(
                        item.issueType.toLowerCase()
                      )}--text text--darken-4 text-capitalize`"
                      >{{ item.issueType }}</v-chip
                    >
                  </template>
                  <template v-slot:item.title="{ item }">
                    <span
                      class="text-capitalize primary--text pt-3 text-truncate"
                      style="max-width: 350px"
                      v-html="item.title"
                    ></span>
                  </template>
                  <template v-slot:item.assignee="{ item }">
                    <div class="d-flex" v-if="item.assignee">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2 white--text"
                            :size="24"
                            :color="
                              item.assignee !== ''
                                ? userColor(item.assignee)
                                : 'primary'
                            "
                          >
                            <v-img
                              v-if="item.assignee"
                              :src="getAssigneeAvatar(item.assignee)"
                              contain
                            />
                          </v-avatar>
                        </template>
                        <span>{{ getAsigneeName(item.assignee) }}</span>
                      </v-tooltip>
                      <span class="text-capitalize">
                        {{ getAsigneeName(item.assignee) }}
                      </span>
                    </div>
                    <div v-else>
                      <span class="text-capitalize">Unassigned</span>
                    </div>
                  </template>
                  <template v-slot:item.reporter="{ item }">
                    <div class="d-flex">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar
                            v-on="on"
                            v-bind="attrs"
                            class="mr-2 white--text"
                            :size="24"
                            :color="
                              item.reporter !== ''
                                ? userColor(item.reporter)
                                : 'primary'
                            "
                          >
                            <v-img
                              v-if="item.reporter"
                              :src="getReporterAvatar(item.reporter)"
                              contain
                            />
                          </v-avatar>
                        </template>
                        <span>{{ getReporterName(item.reporter) }}</span>
                      </v-tooltip>
                      <span class="text-capitalize">{{
                        getReporterName(item.reporter)
                      }}</span>
                    </div>
                  </template>
                  <template v-slot:item.priorities="{ item }">
                    <v-chip
                      label
                      small
                      dark
                      :color="
                        item.priorities
                          ? `${issuePriorityColor(
                              item.priorities.toUpperCase()
                            )} lighten-5`
                          : ''
                      "
                      :class="
                        item.priorities
                          ? `${issuePriorityColor(
                              item.priorities.toUpperCase()
                            )}--text text--darken-4 text-capitlize`
                          : ''
                      "
                      >{{
                        item.priorities ? item.priorities.toUpperCase() : "None"
                      }}</v-chip
                    >
                  </template>

                  <template v-slot:item.status="{ item }">
                    <v-chip
                      label
                      small
                      :color="`${issueStatusColor(item.status)} lighten-5`"
                      :class="`${issueStatusColor(
                        item.issueStatusColor
                      )}--text text--darken-4 text-capitlize`"
                      >{{ item.status }}</v-chip
                    >
                  </template>

                  <template v-slot:item.productModules="{ item }">
                    {{ item.moduleCode }}
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div>
                          <v-icon
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="viewIssue(item)"
                          >
                            fa fa-eye
                          </v-icon>
                        </div>
                      </template>

                      <span>view</span>
                    </v-tooltip>
                  </template>
                </v-data-table></v-responsive
              >
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { AuthService } from "../../../../dashboard/modules/auth";
import issueMixin from "../../../../../packages/tickets/modules/issues/issueMixin";
import { helper } from "@/packages/dashboard/utils";
export default {
  name: "MainDashboard",
  mixins: [issueMixin],

  data: function () {
    return {
      expanded: [],
      singleExpand: true,
      viewIssues: true,
      dialog: false,
      ticketType: "all",
      IssuesPerProject: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Setup/getProjects");
      v.$store.dispatch("Setup/getprojectIssues");
      v.$store.dispatch("Ticket/getIssues");
      v.$store.dispatch("Setup/getAssignees");
      v.$store.dispatch("Setup/getReporters");
      v.$store.dispatch("Setup/getIssueStatus");
    });
  },
  computed: {
    user() {
      return AuthService.user;
    },
    issues: function () {
      return this.$store.getters["Ticket/issueGetters"]("tickets");
    },
    isAdmin: function () {
      return (
        this.user.user_type === "SUPER_ADMIN" ||
        this.user.user_type === "AGENT_ADMIN"
      );
    },
    recentIssues: function () {
      const allIssues = this.issues;
      return allIssues.slice(0, 5);
    },
    unAssignedIssues: function () {
      return this.$store.getters["Ticket/issueGetters"]("tickets").filter(
        (el) => el.asignee === null
      ).length;
    },
    projects: function () {
      return this.$store.getters["Setup/setupGetters"]("projects");
    },
    completedIssues: function () {
      return this.$store.getters["Ticket/issueGetters"]("tickets").filter(
        (d) => {
          return (
            (d.assignee ? d.assignee === this.user.id : false) &&
            d.status === "Done"
          );
        }
      ).length;
    },
    expandableHeaders: function () {
      return [
        {
          text: "Type",
          value: "issueType",
          align: "start",
          sortable: true,
        },
        {
          text: "No.",
          value: "issueCode",
          align: "start",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          align: "start",
          sortable: false,
        },
        {
          text: "Assignee",
          value: "assignee",
          align: "start",
          sortable: true,
        },
        {
          text: "Reporter",
          value: "reporter",
          align: "start",
          sortable: true,
        },
        {
          text: "Priority",
          value: "priorities",
          align: "start",
          sortable: true,
        },
        {
          text: "Status",
          value: "status",
          align: "start",
          sortable: true,
        },
        {
          text: "Project Code",
          value: "projectCode",
          align: "start",
          sortable: true,
        },
        {
          text: "DueDate",
          value: "dueDate",
          align: "start",
          sortable: true,
        },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: true,
        },
      ];
    },
    projectsHeaders() {
      return [
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Name",
          value: "description",
        },
        { text: "Lead", value: "lead" },
        { text: "Type", value: "type" },
        {
          text: "Issues(count)",
          value: "issues",
        },
        { text: "", value: "data-table-expand" },
        { text: "", value: "actions" },
      ];
    },
    ticketsperProject: function () {
      return this.$store.getters["Ticket/issueGetters"]("tickets").filter(
        (i) => {
          return this.ticketType === "all" ? i : i.reporter.id === null;
        }
      );
    },
    assignees: function () {
      return this.$store.getters["Setup/setupGetters"]("assignees");
    },
    reporters: function () {
      return this.$store.getters["Setup/setupGetters"]("reporters");
    },
    AllStatuses: function () {
      return this.$store.getters["Setup/setupGetters"]("status");
    },
    getStatusCount: function () {
      const result = this.AllStatuses.map((f) => {
        return {
          status: f.description,
          count: this.issues.filter((d) => d.status === f.code).length,
        };
      });

      return result;
    },
  },
  methods: {
    loadDetails({ item }) {
      this.issues.filter((el) => el.projectCode === item.code);
    },
    viewItem: function (val) {
      this.dialog = true;
      this.IssuesPerProject = this.issues
        ? this.issues.filter((el) => el.projectCode === val.code)
        : [];
    },
    closeDialog: function (val) {
      this.dialog = val;
    },
    convertArrtoObj(val) {
      if (val !== 0 || val !== null) {
        return val
          ? `${val.first_name[0].toUpperCase()}${val.last_name[0].toUpperCase()}`
          : "N/A";
      } else {
        return "N/A";
      }
    },
    displayReporterName(val) {
      if (val !== 0 || val !== null) {
        return val ? `${val.first_name}${" "}${val.last_name}` : "N/A";
      } else {
        return "N/A";
      }
    },
    userColor(user) {
      return helper.stringToColour(user);
    },
    clickColumn(item) {
      return this.issues.filter((d) => d.projectCode === item.code);
    },
    viewIssue: function (item) {
      console.log(item);
      this.$router.replace({
        name: "ViewSelectedIssue",
        params: { id: item.issueCode },
      });
    },
    getAsigneeName(val) {
      if (val) {
        const data = this.assignees.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].name;
        } else {
          return "";
        }
      }
    },
    getReporterAvatar(val) {
      if (val) {
        const data = this.reporters.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].avatar
            ? `data:image/png;base64,${data[0].avatar}`
            : require("@/assets/avatar.jpeg");
        } else {
          return "";
        }
      }
    },
    getAssigneeAvatar(code) {
      const agent = this.assignees.filter((item) => item.code === code).shift();
      return agent ? process.env.VUE_APP_TEMP_URL + agent.avatar : "";
    },
    getReporterName(val) {
      if (val) {
        const data = this.reporters.filter((d) => d.code === val);
        if (data.length !== 0) {
          return data[0].name;
        } else {
          return "";
        }
      }
    },

    filterIssues() {
      var props = ["status", "name"];
      var result = this.issues
        .filter(function (o1) {
          // filter out (!) items in result2
          return this.AllStatuses.some(function (o2) {
            return o1.status === o2.code; // assumes unique id
          });
        })
        .map(function (o) {
          // use reduce to make objects with only the required properties
          // and map to apply this to the filtered array as a whole
          return props.reduce(function (newo, status) {
            newo[status] = o[status];
            return newo;
          }, {});
        });
      console.log(result);
    },
  },
};
</script>
<style scoped>
.v-card,
.v-card:hover {
  color: green;
}
</style>
