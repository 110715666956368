<template>
  <v-app>
    <v-data-table flat :headers="headers" :items="getItems" :group-by="groupBy" item-key="date"
                  :expanded.sync="flase" >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>User usage list</v-toolbar-title>
        </v-toolbar>
      </template>
      <!-- <template #expanded-item="{ item }">
        <v-card flat class="pr-5"> 
          <v-card-text flat>
            <div v-for="(value, key) in item" :key="key">
              <div><b>{{ key }}:</b></div>
              <div>{{ value }}</div>
            </div>
          </v-card-text>
        </v-card>
      </template> -->
    </v-data-table>
  </v-app>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      groupBy: ['date'],
      headers: [
        { text: 'Date', value: 'date' },
        { text: 'User', value: 'userID', groupable: false, align: 'start' },
        { text: 'Actions', value: 'actions', align: 'start' },
        { text: 'Usage(%)', value: 'usage', align: 'center' }
      ],
      expanded: []
    }
  },
  computed: {
    getItems() {
      const regex = /v1\/([^/?]+)/g;
      const items = this.data.map(item => {
        const urls = item.urls;
        let match;
        const actions = [];
        while ((match = regex.exec(urls)) !== null) {
          actions.push(match[1]);
        }
        const uniqueActions = [...new Set(actions.map((act) => act))]
        return {
          id: item.id,
          date: item.date,
          userID: item.user_id,
          actions: uniqueActions,
          usage: ((item.user_total_activities_per_day / item.total_activities_per_day) * 100).toFixed(2),
        };
      })
      return items;
    }
  },
  methods: {
    expand(item) {
      const index = this.expanded.indexOf(item.id);
      if (index !== -1) {
        this.expanded.splice(index, 1);
        item.expanded = false;
      } else {
        this.expanded.push(item.id);
        item.expanded = true;
      }
    }
  }
}
</script>

<!-- <template>
  <v-app>
    <v-data-table :headers="headers" :items="getItems" item-value="date" :group-by="groupBy">
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>
            User usage list
          </v-toolbar-title>
        </v-toolbar>

      </template>

    </v-data-table>
  </v-app>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      groupBy: [{ key: 'date' }],
      headers: [
      { text: 'Date', value: 'date', },
        { text: 'User', value: 'userID', groupable: false, align: 'start' },
        { text: 'Actions', value: 'actions', align: "end" },
        { text: 'Usage(%)', value: 'usage', align: "end" }
      ]
    }
  },
  mounted() {
    console.log(this.groupingData())
  },
  computed: {
    getItems() {
      const regex = /v1\/([^/?]+)/g;
      const items = this.data.map(item => {
        const urls = item.urls;
        let match;
        const actions = [];
        while ((match = regex.exec(urls)) !== null) {
          actions.push(match[1]);
        }
        const uniqueActions = [...new Set(actions.map((act) => act))]
        return {
          date: item.date,
          userID: item.user_id,
          actions: uniqueActions,
          usage: ((item.user_total_activities_per_day / item.total_activities_per_day) * 100).toFixed(2)
        };
      })
      return items;
    }
  },
  methods: {
    groupingData (){
      const uniqueDates = [...new Set(this.data.map((stat) => stat.date))];
      const groupedData = uniqueDates.map((date) => {
        return this.data.filter((stat) => {
          return stat.date === date;
        });
      });
      return groupedData;
    },
  }
}
</script> -->