<template>
  <v-container>
    <div>
      <v-card
        flat
        class="overflow-auto"
        v-if="ticketLog.length !== 0"
        height="150"
        width="800"
      >
        <v-list class="overflow-auto">
          <v-list-item v-for="(item, i) in ticketLog" :key="i">
            <v-list-item-content cols="12">
              <v-list-item-title>
                <span class="pa-2">{{
                  item.fieldCaption === "Status" ? "Updated" : "Changed"
                }}</span>
                <span small class="font-weight-bold" label>
                  {{ item.fieldCaption }}
                </span>
                <!-- <span v-if="item.oldValue !== ''" class="pa-2">from</span> -->

                <span class="pa-2">on</span>
                {{ formatDate(item.dateAndTime) }}

                <span class="pa-2">at</span>
                {{ formatTime(item.time) }}
              </v-list-item-title>
              <v-list-item-subtitle class="mt-0">
                <v-chip
                  x-small
                  v-if="item.oldValue !== ''"
                  class="text-capitlize text-decoration-line-through"
                  label
                >
                  {{ checkValue(item.oldValue) }}
                </v-chip>

                <span class="pa-2" v-if="item.oldValue === ''"></span>

                <v-icon class="pa-2" v-else>fa fa-long-arrow-right</v-icon>
                <v-chip x-small label class="text-capitlize`">
                  {{ item.newValue }}
                </v-chip>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card flat v-if="ticketLog.length === 0">
        <v-card-text> No Log Available for this Issue </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import issueMixin from "../issueMixin";
import moment from "moment";

export default {
  name: "IssueLog",
  mixins: [issueMixin],
  props: {
    ticketLog: Array,
  },
  data: function () {
    return {};
  },
  computed: {
    latestStatus() {
      const val = this.ticketLog;
      return val.splice(-1);
    },
  },
  methods: {
    checkValue(val) {
      if (val === "") {
        return "";
      } else {
        return val;
      }
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      const val = [year, month, day].join("-");

      var today = new Date(val);
      var m = today.toLocaleString("default", { month: "short" });

      return [year, m, day].join("-");
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    formatTime(val) {
      console.log(val);
      return moment(val, "HH:mm:ss").format("hh:mm A");
    },
  },
};
</script>
