<template>
  <v-container>
    <v-dialog v-model="dialog" persistent width="1200px" height="200%">
      <v-card>
        <v-form v-model="isValid" ref="issueForm">
          <v-card-title class="text-lg-h5 primary--text">
            Create issue
          </v-card-title>

          <v-col>
            <v-card-text class="">
              <v-divider class="mt-n8 mb-2" />
              <v-row class="">
                <v-col cols="12" sm="12" md="4">
                  <p class="mt-1">Project</p>
                  <v-autocomplete
                    v-model="formData.projectCode"
                    :items="projects ? projects : []"
                    item-text="description"
                    item-value="code"
                    label="Project"
                    :filled="formData.projectCode.length === 0 ? true : false"
                    :rules="rules.required"
                    outlined
                    dense
                    class="mt-n3"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <p class="mt-1">Module</p>
                  <v-autocomplete
                    v-model="formData.moduleCode"
                    :items="modules ? modules : []"
                    label="Module"
                    item-text="name"
                    item-value="moduleCode"
                    :filled="formData.moduleCode.length === 0 ? true : false"
                    outlined
                    :rules="rules.required"
                    dense
                    class="mt-n3"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <p class="mt-1">Issue Type</p>
                  <v-autocomplete
                    v-model="formData.issueType"
                    :items="issueTypes ? issueTypes : []"
                    label="Type"
                    item-text="description"
                    item-value="code"
                    :filled="formData.issueType.length === 0 ? true : false"
                    outlined
                    :rules="rules.required"
                    dense
                    class="mt-n3"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="3" class="mt-n8">
                  <p class="">Priority</p>
                  <v-autocomplete
                    v-model="formData.priorities"
                    :items="priorities"
                    :item-text="(item) => item.description"
                    :item-value="(item) => item.code"
                    label="Priority"
                    outlined
                    :filled="formData.priorities.length === 0 ? true : false"
                    :rules="rules.required"
                    dense
                    class="mt-n4"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="8" md="3" class="mt-n8">
                  <span class="mb-1">Title</span>
                  <v-text-field
                    label="Title"
                    v-model="formData.title"
                    outlined
                    dense
                    :rules="rules.required"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="3" class="mt-n8">
                  <span class="mb-1">Required By</span>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    :close-on-click="false"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        outlined
                        prepend-inner-icon="fa fa-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules.required"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                      :min="today"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="8" md="3" class="mt-n8">
                  <span class="mb-1">Planned Due Date</span>
                  <v-menu
                    ref="plannedMenu"
                    v-model="plannedMenu"
                    :close-on-content-click="false"
                    :return-value.sync="plannedDate"
                    transition="scale-transition"
                    :close-on-click="false"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="plannedDate"
                        outlined
                        prepend-inner-icon="fa fa-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules.required"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="plannedDate"
                      no-title
                      scrollable
                      :min="today"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="plannedMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.plannedMenu.save(plannedDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="8" md="12" class="mt-n7">
                  <div
                    class="d-flex flex-column mt-n4"
                    @click="$refs.uploader.$refs.input.click()"
                  >
                    <span class="mb-1">Attachments</span>
                    <div v-if="formData.attachments.length !== 0">
                      <span
                        class="ml-2"
                        v-for="(item, i) in formData.attachments"
                        :key="i"
                      >
                        <v-chip
                          label
                          color="blue lighten-4"
                          small
                          close
                          @click:close="removeAttachments(item)"
                        >
                          <v-icon small color="blue">fa fa-file</v-icon>

                          {{ item.name }}
                        </v-chip>
                      </span>
                    </div>
                    <div
                      class="border"
                      v-if="formData.attachments.length === 0"
                    >
                      <div class="mx-auto d-flex">
                        <v-icon color="blue" :size="15" class="mr-3">
                          fa fa-cloud-upload
                        </v-icon>
                        <div class="mt-1">
                          <span>Click here to add files</span>
                        </div>
                        <v-file-input
                          accept="*"
                          multiple
                          ref="uploader"
                          hide-input
                          prepend-icon=""
                          @change="uploadFile"
                        />
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="mt-n5">
                  <div class="d-flex flex-column">
                    <span>Description</span>
                    <Editor
                      class="editor"
                      v-model="description"
                      :editorToolbar="customToolbar"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <div id="editorInput" style="display: none">
              <div
                style="display: none"
                class="ql-editor"
                v-html="description"
                id="quizOutput"
              ></div>
            </div>
          </v-col>
        </v-form>
        <div align="right">
          <v-btn class="mx-5 mt-4" @click="closeDialog" color="error" text>
            Cancel
          </v-btn>
          <v-btn small class="mt-4 mr-6" @click="saveIssue()" color="success">
            Submit
          </v-btn>
        </div>
        <DialogSpinner />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import helpers from "@/packages/dashboard/utils/helpers/helper.js";
import { VueEditor as Editor } from "vue2-editor";
import SetupMixin from "../../../../setup/SetupMixin";
import issueMixin from "../issueMixin";
import { AuthService } from "../../../../dashboard/modules/auth";
import DialogSpinner from "@/packages/dashboard/plugins/loader/views/DialogSpinner.vue";
import EditorMixin from "../EditorMixin";
// import ExcelUpload from "./ExcelUpload.vue";
export default {
  name: "AddIssue",
  components: {
    Editor,
    DialogSpinner,
  },
  mixins: [SetupMixin, issueMixin, EditorMixin],
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Setup/getProjects");
      v.$store.dispatch("Setup/getissueTypes");
      v.$store.dispatch("Setup/getModules");
    });
  },

  data: () => ({
    isValid: false,
    date: "",
    menu: false,
    switchButton: true,
    today: new Date().toISOString().substr(0, 10),
    formData: {
      description: "",
      issueType: "",
      attachments: [],
      projectCode: "",
      dueDate: "",
      priorities: "",
      title: "",
      moduleCode: "",
      productCode: "",
      plannedDueDate: "",
    },
    description: "",
    plannedMenu: false,
    plannedDate: "",
  }),

  computed: {
    dialog: {
      get() {
        return this.$store.getters["Ticket/issueGetters"]("issuesModal");
      },
      set(value) {
        this.$store.dispatch("addIssue", value);
      },
    },
    rules() {
      return {
        required: [(v) => !!v || "field is required"],
      };
    },
    issueTypes: function () {
      return this.$store.getters["Setup/setupGetters"]("issueTypes");
    },
    status: function () {
      return this.$store.getters["Setup/setupGetters"]("status");
    },
    projects: function () {
      return this.$store.getters["Setup/setupGetters"]("projects");
    },
    assignees: function () {
      return this.$store.getters["Setup/setupGetters"]("assignees");
    },
    priorities: function () {
      return this.$store.getters["Setup/setupGetters"]("priorities");
    },
    user() {
      return AuthService.user;
    },
    modules: function () {
      return this.$store.getters["Setup/setupGetters"]("modules").filter(
        (el) => el.projectCode === this.formData.projectCode
      );
    },
    productModuleCode: function () {
      const data = this.modules.filter(
        (el) => el.moduleCode === this.formData.moduleCode
      );
      return data[0].productCode;
    },
  },
  methods: {
    closeDialog: function () {
      this.$store.dispatch("Ticket/addIssue", false);
    },
    uploadFile: async function (value) {
      if (value) {
        this.formData.attachments = value;
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },

    saveIssue: function () {
      if (!this.isValid) {
        this.$refs.issueForm.validate();
      } else {
        this.formData.description = this.htmlToString(
          document.getElementById("quizOutput")
        );
        const data = { ...this.formData };
        data.dueDate = this.date;
        data.plannedDueDate = this.plannedDate;
        data.productCode = this.productModuleCode;
        const attachments = data.attachments;
        delete data.attachments;
        const formData = helpers.prepareFormData(data);
        attachments.forEach((file) => {
          formData.append("attachments[]", file);
        });
        this.$store.dispatch("Ticket/saveIssue", formData);
        this.resetattchments();
        this.closeDialog();
      }
    },
    removeAttachments: function (val) {
      // console.log(val);
      const indexOfObject = this.formData.attachments.findIndex(
        (el) => el.name === val.name
      );
      this.formData.attachments.splice(indexOfObject, 1);
    },
    resetattchments: function () {
      this.formData = {
        description: "",
        issueType: "",
        attachments: [],
        projectCode: "",
        agent_id: "",
        dueDate: "",
        priorities: "",
        title: "",
        moduleCode: "",
        productCode: "",
      };
    },
  },
};
</script>

<style scoped>
.border {
  height: 38px;
  border: 1.9px dashed #838383;
  background: rgba(227, 242, 253, 0.45) none repeat scroll 0% 0%;
  border-radius: 4px;
  padding-top: 10px;
  /* padding: 1% 0 1% 0; */
  /* flex-direction: column !important; */
  display: flex !important;
  cursor: pointer;
}
.v-input__slot::before {
  border-style: none !important;
}
.editor {
  height: 180px;
}
.v-card__text {
  scrollbar-width: 5px;
}
</style>
