<template>
  <v-dialog persistent v-model="dialog">
    <v-card>
      <v-card-title class="headline">
        Update Issue Status
        <v-spacer />
        <v-btn icon @click="closeDialog">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-card-title>
      <v-toolbar dense flat>
        <div style="max-width: 40%">
          <v-text-field
            filled
            dense
            width="300"
            single-line
            append-inner="fa-solid fa-magnifying-glass"
            class="search mr-2"
            label="Search"
            v-model="search"
          />
        </div>
        <section
          v-if="avatarsStackedLimited && avatarsStackedLimited.length > 0"
          class="avatars-group stacked"
        >
          <v-btn-toggle v-model="selectAssignee" borderless>
            <v-btn
              v-for="avatar in avatarsStackedLimited"
              icon
              :key="`avatar-id-${avatar.id}`"
              class="avatars-group__item"
              :value="avatar.id"
            >
              <profile-avatar :avatar="avatar" />
            </v-btn>
          </v-btn-toggle>
        </section>
      </v-toolbar>
      <v-card-text>
        <kanban
          :issues="
            (selectAssignee ? assigneeSearch : issues) ||
            (search.length > 0 ? searchIssues : issues)
          "
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Kanban from "./Addons/Kanban.vue";
import ProfileAvatar from "./Addons/ProfileAvatar.vue";
import { AuthService } from "@/packages/dashboard/modules/auth";
import issueMixin from "../issueMixin";
export default {
  name: "ChangeStatus",
  mixins: [issueMixin],
  components: {
    Kanban,
    ProfileAvatar,
  },
  data: function () {
    return {
      stackedLimit: 6,
      stackedMenu: false,
      menuMaxHeight: `${60 * 5 + 4}px`,
      dialog: true,
      ticketType: "all",
      search: "",
      selectAssignee: undefined,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Ticket/getValidStatuses");
    });
  },
  computed: {
    avatarsStackedLimited() {
      return this.assignees && this.assignees.length > 0
        ? this.assignees.slice(0, this.stackedLimit)
        : null;
    },
    assignees: function () {
      return this.$store.getters["Setup/setupGetters"]("assignees");
    },
    user() {
      return AuthService.user;
    },
    searchIssues() {
      return this.issues.filter((issue) => {
        return (
          issue.issueCode.toLowerCase().includes(this.search.toLowerCase()) ||
          issue.description.toLowerCase().includes(this.search.toLowerCase()) ||
          issue.issueType.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    assigneeSearch() {
      return this.issues.filter((issue) => {
        return issue.assignee
          ? issue.assignee.id === this.selectAssignee
          : null;
      });
    },
    issues: function () {
      return !this.isAdmin
        ? this.$store.getters["Ticket/issueGetters"]("tickets").filter(
            (d) => d.reporter.id === this.user.id
          )
        : this.$store.getters["Ticket/issueGetters"]("tickets").filter((i) => {
            return this.ticketType === "all" ? i : i.reporter.id === null;
          });
    },
    isAdmin: function () {
      return this.user.user_type === "SUPER_ADMIN" || "ADMIN";
    },
  },
  methods: {},
};
</script>
<style>
.search.v-text-field > .v-input__control > .v-input__slot::before {
  border-style: none !important;
}
.size {
  font-size: 18px;
}
.avatars-group.grid {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
}
.avatars-group.stacked {
  display: flex;
  flex-direction: row;
  direction: ltr;
  max-width: 100%;
  overflow: hidden;
  overflow-x: hidden;
  white-space: nowrap;
  margin-top: -22px;
}
.avatars-group.stacked .v-btn-toggle > * {
  margin-right: -15px;
}
.avatars-group.stacked > *:last-of-type {
  padding-right: 16px;
}
.avatars-group__item {
  cursor: default;
  transition: all 0.1s ease-out;
}
.avatars-group__item.more {
  align-items: center;
  display: flex;
}
.avatars-group .v-avatar {
  box-shadow: none;
}
.avatars-group .v-avatar img {
  padding: 2px;
}
.avatars-group .v-avatar span {
  align-items: center;
  display: flex;
  font-size: 110%;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  letter-spacing: 0.1rem;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.56);
  width: inherit;
}
.v-avatar.bordered {
  box-shadow: 0px 0px 0px 2px #fff inset;
}
.v-avatar.bordered img {
  padding: 2px;
}
.v-avatar.bordered.small {
  box-shadow: 0px 0px 0px 1px #fff inset;
}
.v-avatar.bordered.small img {
  padding: 1px;
}
.text {
  white-space: nowrap;
  padding-right: 15px;
}
.avatars-group__item.more:hover {
  transform: none;
}
.avatars-group__item:hover {
  transform: translateY(-2px);
  z-index: 1;
}
.avatars-group__item {
  border-radius: 100% !important;
  height: 45px !important;
  width: 45px !important;
}
</style>
