class Colors {
  issueType() {
    return {
      bug: "deep-orange",
      epic: "blue",
      improvement: "green",
      newfeature: "teal",
      task: "green",
      none: "grey",
    };
  }

  issuePriority() {
    return {
      High: "red",
      Medium: "orange",
      Low: "blue",
    };
  }
}

export default new Colors();
