<template>
  <div>
    <div ref="barChart" style="width: 30vw; height: 40vh"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "BarChart",
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  methods: {
    drawChart() {
      const chart = echarts.init(this.$refs.barChart);
      const uniqueDates = [...new Set(this.data.map((stat) => stat.date))];
      const groupedData = uniqueDates.map((date) => {
        return this.data.filter((stat) => {
          return stat.date === date;
        });
      });
      const currentDate = new Date().toISOString().slice(0, 10); // get the date in ISO format and slice the time part
      const usageToday = groupedData.filter((innerItem) => {
        return innerItem[0].date === currentDate;
      }).flat();

      const barData = {
        type: "bar",
        data: usageToday.map((item) => {
          return ((item.user_total_activities_per_day / item.total_activities_per_day) * 100).toFixed(2);
        }),
      };
      const option = {
        title: {
          text: "Daily usage on bar chart",
          left: "center",
          top: "auto",
          textStyle: {
            color: "rgba(32, 51, 84, 0.8)",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#283b56",
            },
          },
        },
        toolbox: {
          show: true,
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100,
        },
        xAxis:{
            type: "category",
            boundaryGap: true,
            data: usageToday.map((users) => {
              return users.user_id
            }),
          },
        yAxis: [
          {
            type: "value",
            scale: true,
            name: "percentage",
          },
        ],
          series: [barData,],
      };
      chart.setOption(option);
    },
  },
  watch: {
    data: {
      handler(val) {
        if (val) this.drawChart();
      },
      deep: true,
    },
  },
};
</script>
