import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,[_c(VSlideGroup,{staticClass:"slider--body--prev slider--body--next",attrs:{"show-arrows":""}},_vm._l((_vm.filteredItems),function(item,i){return _c(VSlideItem,{key:i,staticClass:"slider--item"},[_c(VAutocomplete,{key:i,staticClass:"mx-2",attrs:{"items":_vm.filterItems[item.label],"item-text":function (item) { return item.description; },"item-value":function (item) { return item.code; },"placeholder":item.label,"multiple":"","outlined":"","dense":""},on:{"change":function($event){return _vm.filterSelected()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
return [_c(VChip,_vm._b({key:item.code,staticClass:"ma-1 primary",attrs:{"label":"","small":""}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.description)+" ")])]}}],null,true),model:{value:(item.selectedValues),callback:function ($$v) {_vm.$set(item, "selectedValues", $$v)},expression:"item.selectedValues"}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }